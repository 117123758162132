import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { DiscriminatorRequest } from '../../requests/risk-classification/discriminator.request';
import { CompleteFlowchartRequest } from '../../requests/risk-classification/complete-flowchart.request';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { ProtocolResponse } from '../../responses/risk-classification/protocol.response';
import { ProtocolStruct } from '../../structs/risk-classification/protocol.struct';
import { CreateDiscriminatorResponse } from '../../responses/risk-classification/create-discriminator.response';
import { GetDiscriminatorStructResponse } from '../../responses/risk-classification/get-discriminator-struct.response';

@Injectable({
  providedIn: 'root'
})
export class DiscriminatorService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  GetDiscriminatorByIdDiscriminator(idDiscriminator: number): Observable<GetDiscriminatorStructResponse> {
    let uri = `Discriminator/idDiscriminator/${idDiscriminator}`

    return this.httpClient.get<GetDiscriminatorStructResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  CreateDiscriminator(body: DiscriminatorRequest): Observable<CreateDiscriminatorResponse> {
    let uri = `Discriminator`

    return this.httpClient.post<CreateDiscriminatorResponse>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateDiscriminator(body: DiscriminatorRequest): Observable<ReturnStruct> {
    let uri = `Discriminator`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  DeleteDiscriminator(idDiscriminator: number): Observable<ReturnStruct> {
    let uri = `Discriminator/idDiscriminator/${idDiscriminator}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
