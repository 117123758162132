import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CompleteFlowchartRequest } from '../../requests/risk-classification/complete-flowchart.request';
import { SimpleFlowchartRequest } from '../../requests/risk-classification/simple-flowchart.request';
import { CreateFlowchartResponse } from '../../responses/risk-classification/create-flowchart.response';
import { FlowchartResponse } from '../../responses/risk-classification/flowchart.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { ProtocolResponse } from '../../responses/risk-classification/protocol.response';
import { ProtocolStruct } from '../../structs/risk-classification/protocol.struct';

@Injectable({
  providedIn: 'root'
})
export class FlowchartService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  GetFlowchart(idFlowchart: number): Observable<FlowchartResponse> {
    let uri = `Flowchart/idFlowchart/${idFlowchart}`

    return this.httpClient.get<FlowchartResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  PostCompleteFlowchart(body: CompleteFlowchartRequest): Observable<ReturnStruct> {
    let uri = `Flowchart/completeFlowchart`

    return this.httpClient.post<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  PostSimpleFlowchart(body: SimpleFlowchartRequest): Observable<CreateFlowchartResponse> {
    let uri = `Flowchart/simpleFlowchart`

    return this.httpClient.post<CreateFlowchartResponse>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateCompleteFlowchart(body: CompleteFlowchartRequest): Observable<ReturnStruct> {
    let uri = `Flowchart/completeFlowchart`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateSimpleFlowchart(body: SimpleFlowchartRequest): Observable<ReturnStruct> {
    let uri = `Flowchart`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  DeleteFlowchart(idFlowchart: number): Observable<ReturnStruct> {
    let uri = `Flowchart/idFlowchart/${idFlowchart}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
