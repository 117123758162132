<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" class="btn-secundary" mat-stroked-button
    type="button" (click)="addNext()" [disabled]="formGroup.invalid">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar novo fluxograma
</button>

<div [formGroup]="formGroup">
    <div class="row" formArrayName="{{formArrayName}}"
        *ngFor="let flowchart of formGroup.get(formArrayName)['controls']; let i = index;">
        <div class="col-md-10">
            <cdk-accordion class="profile-register-accordion">
                <cdk-accordion-item #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                    role="button" tabindex="0" [attr.id]="'accordion-header-' + i"
                    [attr.aria-expanded]="accordionItemModule.expanded" [attr.aria-controls]="'accordion-body-' + i">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        {{listFlowchart[i].flowchartName}} - ({{listFlowchart[i].order}})
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + i"
                        [attr.aria-labelledby]="'accordion-header-' + i">
                        <div class="feature-content">
                            <div class="row">
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Nome</mat-label>
                                            <input (blur)="changeName($event.target.value,i)" matInput type="text"
                                                formControlName="name">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>ordem</mat-label>
                                            <input (blur)="changeOrder($event.target.value,i)" matInput type="number"
                                                formControlName="order">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Idade miníma</mat-label>
                                            <input matInput [mask]="masks.age" type="text" formControlName="minAge">
                                            <mat-error
                                                *ngIf="formGroup.controls[formArrayName].controls[i].get('minAge').invalid">Idade
                                                inválida</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>idade máxima</mat-label>
                                            <input (keyup)="validateAge($event.target.value, i, 'max')" matInput
                                                [mask]="masks.age" type="text" formControlName="maxAge">
                                            <mat-error
                                                *ngIf="formGroup.controls[formArrayName].controls[i].get('maxAge').invalid">Idade
                                                inválida</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Tipo do fluxograma</mat-label>
                                            <mat-select formControlName="idFlowchartType">
                                                <mat-option *ngFor="let item of listFlowchartType"
                                                    [value]="item.idFlowchartType">{{item.flowchartTypeName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Grupo do fluxograma</mat-label>
                                            <mat-select [multiple]="true" formControlName="idFlowchartGroup">
                                                <mat-option *ngFor="let item of listFlowchartGroup"
                                                    [value]="item.idFlowchartGroup">{{item.flowchartGroupName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-5 col-md-3">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Descrição</mat-label>
                                            <input matInput type="text" formControlName="description">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <app-discriminator-list *ngIf="listPriorityColor && listPriorityColor.length"
                                [listDiscriminator]="listFlowchart[i].listDiscriminators" [flowchartForm]="flowchart"
                                [isLoading]="isLoading" [listPriorityColor]="listPriorityColor"
                                [listDiscriminatorType]="listDiscriminatorType" [listCounseling]="listCounseling"
                                [idProtocol]="formGroup.get('idProtocol').value"
                                (selectedValue)="UpdateArrayDiscriminator(i,$event)" (saveFlowchart)="saveFlowchart(i)">
                            </app-discriminator-list>
                        </div>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
        </div>
        <div class="col-md-2">
            <div class="row">
                <div class="col-6 add-remove-container">
                    <button class="panel-origin-button btn-primary" mat-mini-fab type="button" (click)="addNext(i)">
                        <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                    </button>
                </div>
                <div class="col-6 add-remove-container">
                    <button class="panel-origin-button btn-primary" mat-mini-fab type="button"
                        (click)="openDeleteModal(i)">
                        <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="!formGroup.get(formArrayName)['controls'][i].pristine">
                <div class="col-md-12 add-remove-container">
                    <button class="btn-primary btn-block save-flowchart" mat-mini-fab type="button"
                        (click)="saveFlowchart(i)" [disabled]="!formGroup.get(formArrayName)['controls'][i].valid">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        <span *ngIf="!isLoading">Salvar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>