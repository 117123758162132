import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FlowchartDeleteModalComponent } from '../../flowchart-accordion/flowchart-delete-modal/flowchart-delete-modal.component';

@Component({
  selector: 'app-discriminator-delete-modal',
  templateUrl: './discriminator-delete-modal.component.html',
  styleUrls: ['./discriminator-delete-modal.component.css']
})
export class DiscriminatorDeleteModalComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,
  public matDialogRef: MatDialogRef<FlowchartDeleteModalComponent>,
) { }

  ngOnInit(): void {

  }

  clickDelete() {
    this.matDialogRef.close({ deleteDiscriminator: true });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteDiscriminator: false });
  }

}
