import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportTypeService } from 'src/app/shared/services/API/report/report-type.service';
import { CompetenceSelectService } from 'src/app/shared/services/API/billing/competence-select.service';
import { AdminProtocolService } from 'src/app/shared/services/API/risk-classification/admin-protocol.service';
import { HealthUnitProtocolService } from 'src/app/shared/services/API/risk-classification/health-unit-protocol.service';
import { EvasionTimeService } from 'src/app/shared/services/API/user/evasion-time.service';
import { HealthUnitGroupAdminService } from 'src/app/shared/services/API/user/health-unit-group-admin.service';
import { ListAdminModuleService } from 'src/app/shared/services/API/user/list-admin-module.service';
import { TimeZoneService } from 'src/app/shared/services/API/user/time-zone.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { EvasionTimeModel } from 'src/app/shared/services/models/user/evasion-time.model';
import { HealthUnitCityModel } from 'src/app/shared/services/models/user/health-unit-city.model';
import { HealthUnitGroupModel } from 'src/app/shared/services/models/user/health-unit-group-model.model';
import { TimeZone } from 'src/app/shared/services/models/user/time-zone.model';
import { HealthUnitAdminRequest } from 'src/app/shared/services/requests/admin-user/health-unit-admin.request';
import { HealthUnitProtocolRequest } from 'src/app/shared/services/requests/risk-classification/health-unit-protocol.request';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';
import { ReportTypeStruct } from 'src/app/shared/services/structs/report/report-type.struct';
import { CompetenceSelectStruct } from 'src/app/shared/services/structs/billing/competence-select.struct';
import { HealthUnitLogoModalComponent } from './health-unit-logo-modal/health-unit-logo-modal.component';
import { HealthUnitDataService } from 'src/app/shared/services/API/billing/health-unit-data.service';
import { HealthUnitDataRequest } from 'src/app/shared/services/requests/billing/health-unit-data.request';
import { BillingLookupService } from 'src/app/shared/services/API/billing/billing-lookup.service';
import { Coordination } from 'src/app/shared/services/models/billing/coordination.model';
import { Supervision } from 'src/app/shared/services/models/billing/supervision.model';
import { LoginTimeout } from 'src/app/shared/services/models/user/login-timeout.model';
import { LoginTimeoutService } from 'src/app/shared/services/API/user/login-timeout.service';
import { LoginTimeoutEnum } from 'src/app/shared/enum/login-timeout.enum';
import { environment } from 'src/environments/environment';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { HealthUnitStateService } from 'src/app/shared/services/API/user/health-unit-state.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PostHealthUnitAdminService } from 'src/app/shared/services/API/orchestrator-user/post-health-unit-admin.service';
import { PasswordExpirationTimeModel } from 'src/app/shared/services/models/user/password-expiration-time.model';
import { PasswordExpirationTimeService } from 'src/app/shared/services/API/user/password-expiration-time.service';
import { LoginType } from 'src/app/shared/services/models/user/login-type.model';
import { LookupService } from 'src/app/shared/services/API/user/lookup.service';
import { HealthUnitLoginTypeEnum } from 'src/app/shared/enum/user/health-unit-login-type.enum';
import { EmailDomain } from 'src/app/shared/services/models/user/email-domain.model';
import { UserService } from 'src/app/shared/services/API/user/user.service';
import { UserModel } from 'src/app/shared/services/models/user/user.model';
import { ChangeMasterUserModalComponent } from './change-master-user-modal/change-master-user-modal.component';
import { AdminUserRoleEnum } from 'src/app/shared/enum/admin-user.enum';
import { TemplateBiService } from 'src/app/shared/services/API/business-intelligence/template-bi.service';
import { TemplateBi } from 'src/app/shared/services/models/business-intelligence/template-bi.model';
import { TemplateBiConfirmModalComponent } from './template-bi-confirm-modal/template-bi-confirm-modal.component';
import { ReportTypeEnum } from 'src/app/shared/enum/report/report-type.enum';
import { TemplateEnum } from 'src/app/shared/enum/report/template.enum';
import { HealthUnitTemplate } from 'src/app/shared/services/models/report/health-unit-template.model';
import { TemplateStruct } from 'src/app/shared/services/structs/report/template.struct';
import { GroupOriginService } from 'src/app/shared/services/API/medical-record/group-origin.service';
import { GroupOrigin } from 'src/app/shared/services/models/medical-record/group-origin.model';
import { OriginAndArrivalTypeRequest } from 'src/app/shared/services/requests/medical-record/origin-and-arrival-type.request';
import { GenericAlertModalComponent } from 'src/app/shared/components/generic-alert-modal/generic-alert-modal.component';
import { VerifyHealthUnitHasIntegrationRequest } from 'src/app/shared/services/requests/integration-relation-config/verify-health-unit-has-integration.request';
import { HealthUnitIntegrationRelationConfigService } from 'src/app/shared/services/API/integration-relation-config/health-unit-integration-relation-config.service';
import { UserExpirationTimeModel } from 'src/app/shared/services/models/user/user-expiration-time.model';
import { GetAllLookupResponse } from 'src/app/shared/services/responses/user/get-all-lookup.response';
import { ProfessionModel } from 'src/app/shared/services/models/user/profession.model';
import { ProfessionService } from 'src/app/shared/services/API/user/profession.service';
import { ProfessionEnum } from 'src/app/shared/enum/profession.enum';

@Component({
  selector: 'app-health-unit-register',
  templateUrl: './health-unit-register.component.html',
  styleUrls: ['./health-unit-register.component.css']
})
export class HealthUnitRegisterComponent implements OnInit {

  constructor(
    private healthUnitGroupAdminService: HealthUnitGroupAdminService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private timeZoneService: TimeZoneService,
    private evasionTimeService: EvasionTimeService,
    private loginTimeoutService: LoginTimeoutService,
    private listAdminModuleService: ListAdminModuleService,
    public dialog: MatDialog,
    private adminProtocolService: AdminProtocolService,
    private healthUnitProtocolService: HealthUnitProtocolService,
    private reportTypeService: ReportTypeService,
    private templateBiService: TemplateBiService,
    private competenceSelectService: CompetenceSelectService,
    private healthUnitDataService: HealthUnitDataService,
    private billingLookupService: BillingLookupService,
    public viaCepService: ViaCepService,
    public healthUnitStateService: HealthUnitStateService,
    public postHealthUnitAdminService: PostHealthUnitAdminService,
    private passwordExpirationTimeService: PasswordExpirationTimeService,
    private lookupService: LookupService,
    private userService: UserService,
    private groupOriginService: GroupOriginService,
    private healthUnitIntegrationRelationConfigService: HealthUnitIntegrationRelationConfigService,
    private professionService: ProfessionService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_healthUnit;
  public model: FormGroup;
  public masks: Masks;

  public logo: string;
  public newLogo: string;
  public urlListState: string;
  public urlListCity: string;
  public baseEmail: string = "noreply@";

  public idHealthUnit: number;
  public idMasterUser: number;
  public idSadtTemplate: number;
  public idMaxValue: number = LoginTimeoutEnum.MaxValue;
  public url: number = LoginTimeoutEnum.MaxValue;

  public listPasswordExpirationTime: PasswordExpirationTimeModel[] = [];
  public listLoginType: LoginType[] = [];
  public listUserExpirationTime: UserExpirationTimeModel[] = [];
  public listUserWithAccessInMasterModule: UserModel[];
  public listTemplate: TemplateStruct[];
  public preSelectCity: any[] = [];
  public preSelectState: any[] = [];
  public listCompetenceSelectStruct: CompetenceSelectStruct[];
  public listCoordination: Coordination[];
  public listSupervision: Supervision[];
  public listModule: ModuleStruct[];
  public listTimeZone: TimeZone[];
  public listEvasionTime: EvasionTimeModel[];
  public listLoginTimeout: LoginTimeout[];
  public listHealthUnitGroup: HealthUnitGroupModel[];
  public listCity: HealthUnitCityModel[];
  public listProtocol: ProtocolModel[];
  public listProtocolAux: ProtocolModel[] = [];
  public listTemplateProtocol: ProtocolModel[];
  public listProtocolHealthUnit: ProtocolModel[];
  public listAllReportType: ReportTypeStruct[];
  public listAllTemplateBi: TemplateBi[];
  public listGroupOrigin: GroupOrigin[] = [];
  public healthUnitTemplateForAdminReport: HealthUnitTemplate = new HealthUnitTemplate;

  public isTemplateForAdminReportUpdate: boolean = false;
  public isHealthUnitGroupMaster: boolean = false;
  public hideUserMasterSelect: boolean = false;
  public hasPermissionToChangeMaster: boolean = false;
  public hasPermissionToViewPreviewPassword: boolean = false;
  public hide: boolean = false;
  public disabledFieldEmailDomain: boolean = false;
  public isSelectCity: boolean = false;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public isLoadingCep: boolean;
  public listProfession: ProfessionModel[];

  ngOnInit(): void {
    this.idSadtTemplate = null;
    this.isLoading = false;
    this.urlListState = environment.urlApiUser + "HealthUnitState";

    let token = JSON.parse(sessionStorage.getItem("token"));
    let listRole: any[] = token.listRole;
    this.hasPermissionToChangeMaster = listRole.find(x => x.idRole == AdminUserRoleEnum.consulting_healthUnit_alterar_usuario_master) ? true : false;
    this.hasPermissionToViewPreviewPassword = listRole.find(x => x.idRole == AdminUserRoleEnum.consulting_healthunit_visualizar_senha_temporaria) ? true : false;
    this.getPasswordExpirationTime();

    this.model = this.formBuilder.group({
      userName: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      login: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      idProfession: [ProfessionEnum.Administrador, [Validators.required]],
      temporaryPassword: ['', [Validators.required, VerifyPassword()]],
      healthUnitName: ['', [Validators.required]],
      idTimeZone: ['', [Validators.required]],
      idEvasionTime: ['', [Validators.required]],
      idLoginTimeout: ['', [Validators.required]],
      haveLogo: [''],
      idHealthUnitGroup: [''],
      listModulePermission: [''],
      templateBi: [''],
      idGroupOrigin: [''],
      listIdProtocol: ['', [Validators.required]],
      listIdTemplate: [''],
      cnes: [''],
      supervision: [null],
      coordination: [null],
      isBillingSigtap: [''],
      idProcedureSigtapCompetence: [''],
      hasFlowchartIA: [false],
      firstChildGroup: this.formBuilder.group({
        idHealthUnitState: [, [Validators.required]],
        idHealthUnitCity: [, [Validators.required]],
      }),
      cityIbgeCode: [''],
      country: [''],
      neighborhood: [''],
      addressSupplement: [''],
      houseNumber: ['', [Validators.required]],
      zipCode: [''],
      street: [''],
      healthUnitEmail: [{ value: '', disabled: true }],
      idPasswordExpirationTime: ['', [Validators.required]],
      idLoginType: ['', [Validators.required]],
      idUserExpirationTime: [null],
      emailDomain: [{ value: '', disabled: true }],
      idNewUserMaster: [''],
      hasICPBrasilCertificate: [false],
      a3Quantity: [null],
      isHealthUnitBlocked: [false, [Validators.required]],
      canReturnToQueue: [true, [Validators.required]],
    });

    this.masks = this.maskService.getMasks();

    this.idHealthUnit == null;
    this.healthUnitTemplateForAdminReport.idTemplate = TemplateEnum.PadrãoAdminReport;
    this.isUpdate == false;
    this.model.get('templateBi').setValue("-1");
    if (this.activatedRoute.snapshot.paramMap.get('idHealthUnit'))
      this.idHealthUnit = parseInt(this.activatedRoute.snapshot.paramMap.get('idHealthUnit'));

    if (this.idHealthUnit != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.listHealthUnitProtocol();
      this.model.get('cpf').disable();
      this.populateHealthUnitData(this.idHealthUnit);
      this.populateAppliedTemplateBi(this.idHealthUnit);
      this.populateSelectUserWithMasterModuleAccess(this.idHealthUnit);
    }

    this.populateLookupSelect();
    this.populateModuleSelect();
    this.populateHealthUnitGroupSelect();
    this.populateModuleSelect();
    this.populateTimeZoneSelect();
    this.populateEvasionTimeSelect();
    this.populateLoginTimeoutSelect();
    this.populateTemplate();
    this.populateTemplateBi();
    this.populateProtocolMultiSelect();
    this.populateBillingSigtapSelect();
    this.populatUserLookupSelect();
    this.populateGroupOriginSelect();
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.model.get('healthUnitEmail').value && !/([\w-]+\.)+[\w-]{2,20}$/.test(this.model.get('healthUnitEmail').value)) {
      this.alertService.show('Aviso', "Domínio do email da unidade inválido", AlertType.warning);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let healthUnitAdminRequest = new HealthUnitAdminRequest;
    healthUnitAdminRequest.cpf = this.model.get('cpf').value;
    healthUnitAdminRequest.email = this.model.get('email').value;
    healthUnitAdminRequest.login = this.model.get('login').value;
    healthUnitAdminRequest.phone = this.model.get('phone').value;
    let listIdTemplate: number[] = [];
    listIdTemplate.push(...this.model.get('listIdTemplate').value);
    healthUnitAdminRequest.listIdTemplate = listIdTemplate?.filter(value => value !== null);
    healthUnitAdminRequest.listTemplate = this.listTemplate;
    healthUnitAdminRequest.password = this.model.get('temporaryPassword').value;
    healthUnitAdminRequest.userMasterName = this.model.get('userName').value;
    healthUnitAdminRequest.healthUnitName = this.model.get('healthUnitName').value;
    healthUnitAdminRequest.idTimeZone = this.model.get('idTimeZone').value;
    healthUnitAdminRequest.idEvasionTime = this.model.get('idEvasionTime').value;
    healthUnitAdminRequest.idLoginTimeout = this.model.get('idLoginTimeout').value;
    healthUnitAdminRequest.haveLogo = this.model.get('haveLogo').value ? this.model.get('haveLogo').value : false;
    healthUnitAdminRequest.idCity = this.model.get('firstChildGroup').get('idHealthUnitCity').value;
    healthUnitAdminRequest.idHealthUnitGroup = this.model.get('idHealthUnitGroup').value ? this.model.get('idHealthUnitGroup').value : null;
    healthUnitAdminRequest.listIdModule = this.model.get('listModulePermission').value ? this.model.get('listModulePermission').value : [];
    healthUnitAdminRequest.idUserMaster = this.idMasterUser;
    healthUnitAdminRequest.newHealthUnitLogo = this.newLogo;
    healthUnitAdminRequest.idSadtTemplate = this.idSadtTemplate;
    healthUnitAdminRequest.healthUnitTemplateForAdminReport = this.healthUnitTemplateForAdminReport;
    healthUnitAdminRequest.isTemplateForAdminReportUpdate = this.isTemplateForAdminReportUpdate;
    healthUnitAdminRequest.cnes = this.model.get('cnes').value ? this.model.get('cnes').value : null;
    healthUnitAdminRequest.idCoordination = this.model.get('coordination').value;
    healthUnitAdminRequest.idSupervision = this.model.get('supervision').value;
    healthUnitAdminRequest.isBillingSigtap = this.model.get('isBillingSigtap').value ? this.model.get('isBillingSigtap').value : false;
    healthUnitAdminRequest.idProcedureSigtapCompetence = healthUnitAdminRequest.isBillingSigtap ? this.model.get('idProcedureSigtapCompetence').value : null;
    healthUnitAdminRequest.isHealthUnitGroupMaster = this.isHealthUnitGroupMaster;
    healthUnitAdminRequest.codIbge = this.model.get('cityIbgeCode').value;
    healthUnitAdminRequest.hasFlowchartIA = this.model.get('hasFlowchartIA').value;
    healthUnitAdminRequest.country = this.model.get('country').value;
    healthUnitAdminRequest.neighborhood = this.model.get('neighborhood').value;
    healthUnitAdminRequest.houseNumber = this.model.get('houseNumber').value;
    healthUnitAdminRequest.zipCode = this.model.get('zipCode').value?.replace('-', '');
    healthUnitAdminRequest.street = this.model.get('street').value;
    healthUnitAdminRequest.addressSupplement = this.model.get('addressSupplement').value;
    healthUnitAdminRequest.healthUnitEmail = this.model.get('healthUnitEmail').value;
    healthUnitAdminRequest.idPasswordExpirationTime = this.model.get('idPasswordExpirationTime').value;
    healthUnitAdminRequest.idLoginType = parseInt(this.model.get('idLoginType').value);
    healthUnitAdminRequest.idUserExpirationTime = this.model.get('idUserExpirationTime').value;
    healthUnitAdminRequest.hasICPBrasil = this.model.get('hasICPBrasilCertificate').value;
    healthUnitAdminRequest.a3Quantity = this.model.get('a3Quantity').value;
    healthUnitAdminRequest.isHealthUnitBlocked = this.model.get('isHealthUnitBlocked').value ? this.model.get('isHealthUnitBlocked').value : false;
    healthUnitAdminRequest.canReturnToQueue = this.model.get('canReturnToQueue').value;
    healthUnitAdminRequest.idTemplateBi = this.model.get('templateBi').value ? this.model.get('templateBi').value : null;
    healthUnitAdminRequest.idGroupOrigin = this.model.get('idGroupOrigin').value ? parseInt(this.model.get('idGroupOrigin').value) : null;
    healthUnitAdminRequest.idProfession = this.model.get('idProfession').value && this.model.get('idProfession').value != 0 ? parseInt(this.model.get('idProfession').value) : ProfessionEnum.Administrador;
    if (healthUnitAdminRequest.idLoginType == HealthUnitLoginTypeEnum.google
      || healthUnitAdminRequest.idLoginType == HealthUnitLoginTypeEnum.office365) {

      let listEmailDomainRequest = this.model.get('emailDomain').value.split(',');
      listEmailDomainRequest.forEach(element => {
        let auxEmailDomain = new EmailDomain();
        auxEmailDomain.domainName = element;
        healthUnitAdminRequest.listEmailDomain.push(auxEmailDomain);
      });
    }

    if (this.isUpdate && healthUnitAdminRequest.idGroupOrigin != null){
      let verifyHealthUnitHasIntegrationRequest = new VerifyHealthUnitHasIntegrationRequest();
      verifyHealthUnitHasIntegrationRequest.listIdHealthUnit.push(this.idHealthUnit);
 
     this.verifyHealthUnitsHasIntegration(verifyHealthUnitHasIntegrationRequest, healthUnitAdminRequest)
    }
    else if (this.isUpdate) {
      this.updateHealthUnit(healthUnitAdminRequest);
    }
    else{
      this.createHealthUnit(healthUnitAdminRequest);
    }
     

  }

  changeReportTemplateId(event: any) {
    if (event.reportType.idReportType == ReportTypeEnum.Admissão)
      this.healthUnitTemplateForAdminReport.idTemplate = event.result;
    else
      this.idSadtTemplate = event.result;
  }

  populateTemplate() {
    this.reportTypeService.listReportTypeWithTemplate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAllReportType = response.listAllReportType;
        this.listTemplate = this.listAllReportType.reduce(
          (acc: TemplateStruct[], reportType: ReportTypeStruct) => {
            const templatesWithReportTypeName = reportType.listTemplate.map((template: TemplateStruct) => {
              return {
                ...template,
                reportTypeName: reportType.reportTypeName,
                idReportType: reportType.idReportType,
                disabled: false,
                selected: false
              };
            });
            return acc.concat(templatesWithReportTypeName);
          },
          []
        );
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateTemplateBi() {
    this.templateBiService.listTemplateBiSelect().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAllTemplateBi = response.listTemplateBi;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateAppliedTemplateBi(idHealthUnit: number) {
    this.templateBiService.getHealthUnitTemplate(idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('templateBi').setValue(response.idTemplateBi.toString());
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitData(idHealthUnit: number) {
    this.postHealthUnitAdminService.getHealthUnit(idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == -1) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.healthUnitTemplateForAdminReport = response.healthUnitTemplateForAdminReport;
        if (!this.healthUnitTemplateForAdminReport) {
          this.healthUnitTemplateForAdminReport = new HealthUnitTemplate;
          this.healthUnitTemplateForAdminReport.idTemplate = TemplateEnum.PadrãoAdminReport;
        }
        else
          this.isTemplateForAdminReportUpdate = true;

        this.urlListCity = environment.urlApiUser + `HealthUnitCity/idHealthUnitState/${response.healthUnitAdminStruct.idHealthUnitState}`;

        this.model.get('userName').setValue(response.masterUser ? response.masterUser.userName : "");
        if (this.hasPermissionToViewPreviewPassword == true) {
          if (response.masterUser == null || response.masterUser.isFirstAccess == true)
            this.model.get('temporaryPassword').setValue(response.masterUser ? response.masterUser.password : "");
          else
            this.model.get('temporaryPassword').setValidators([VerifyPassword()]); this.model.get('temporaryPassword').updateValueAndValidity();
        }
        else
          this.model.get('temporaryPassword').setValidators(null); this.model.get('temporaryPassword').updateValueAndValidity();

        this.model.get('healthUnitEmail').enable();
        this.idMasterUser = response.masterUser ? response.masterUser.idUser : null;
        this.model.get('cpf').setValue(response.masterUser ? response.masterUser.cpf : "");
        this.model.get('phone').setValue(response.masterUser ? response.masterUser.phone : "");
        this.model.get('login').setValue(response.masterUser ? response.masterUser.login : "");
        this.model.get('email').setValue(response.masterUser ? response.masterUser.email : "");
        this.model.get('idProfession').setValue(response.masterUser ? response.masterUser.idProfession : ProfessionEnum.Administrador);
        this.model.get('healthUnitName').setValue(response.healthUnitAdminStruct.healthUnitName);
        this.model.get('idTimeZone').setValue(response.healthUnitAdminStruct.idTimeZone.toString());
        this.model.get('idEvasionTime').setValue(response.healthUnitAdminStruct.idEvasionTime.toString());
        this.model.get('idLoginTimeout').setValue(response.healthUnitAdminStruct.idLoginTimeout.toString());
        this.model.get('haveLogo').setValue(response.healthUnitAdminStruct.isLogoUploaded);
        this.model.get('idHealthUnitGroup').setValue(response.healthUnitAdminStruct.idHealthUnitGroup ? response.healthUnitAdminStruct.idHealthUnitGroup.toString() : null);
        this.model.get('listModulePermission').setValue(response.listModulePermission.map(c => c.idModule.toString()));
        this.model.get('cnes').setValue(response.healthUnitAdminStruct.cnes);
        this.model.get('coordination').setValue(response.healthUnitAdminStruct.idCoordination);
        this.model.get('supervision').setValue(response.healthUnitAdminStruct.idSupervision);
        this.model.get('isBillingSigtap').setValue(response.healthUnitAdminStruct.isBillingSigtap);
        this.isHealthUnitGroupMaster = response.healthUnitAdminStruct.isHealthUnitGroupMaster;
        this.model.get('hasFlowchartIA').setValue(response.healthUnitAdminStruct.hasFlowchartIA);
        this.model.get('country').setValue(response.healthUnitAdminStruct.country);
        this.model.get('neighborhood').setValue(response.healthUnitAdminStruct.neighborhood);
        this.model.get('houseNumber').setValue(response.healthUnitAdminStruct.houseNumber);
        this.model.get('zipCode').setValue(response.healthUnitAdminStruct.zipCode);
        this.model.get('street').setValue(response.healthUnitAdminStruct.street);
        this.model.get('addressSupplement').setValue(response.healthUnitAdminStruct.addressSupplement);
        this.model.get('healthUnitEmail').setValue(response.healthUnitAdminStruct.healthUnitEmail);
        this.model.get('idPasswordExpirationTime').setValue(response.healthUnitAdminStruct.idPasswordExpirationTime.toString());  
        this.model.get('idLoginType').setValue(response.healthUnitAdminStruct.idLoginType.toString());
        this.model.get('idUserExpirationTime').setValue(response.healthUnitAdminStruct.idUserExpirationTime);
        this.model.get('hasICPBrasilCertificate').setValue(response.healthUnitAdminStruct.hasICPBrasil);
        this.model.get('a3Quantity').setValue(response.healthUnitAdminStruct.a3Quantity);
        this.model.get('isHealthUnitBlocked').setValue(response.healthUnitAdminStruct.isHealthUnitBlocked);
        this.model.get('canReturnToQueue').setValue(response.healthUnitAdminStruct.canReturnToQueue);
        this.model.get('idGroupOrigin').setValue(response.healthUnitAdminStruct.idGroupOrigin ? response.healthUnitAdminStruct.idGroupOrigin.toString() : null);

        this.model.get('listIdTemplate').setValue([
          response.healthUnitAdminStruct.idAdmissionTemplate,
          response.healthUnitAdminStruct.idSadtTemplate
        ]);

        if (this.model.get('listIdTemplate') && this.model.get('listIdTemplate').value.length > 0) {
          this.listTemplate.forEach((item: TemplateStruct) => {
            if (item.idTemplate === response.healthUnitAdminStruct.idAdmissionTemplate) {
              this.listTemplate.forEach((otherItem: TemplateStruct) => {
                if (otherItem.idReportType === ReportTypeEnum.Admissão && otherItem.idTemplate !== response.healthUnitAdminStruct.idAdmissionTemplate)
                  otherItem.disabled = true;

                if (otherItem.idReportType === ReportTypeEnum.Admissão && otherItem.idTemplate === response.healthUnitAdminStruct.idAdmissionTemplate)
                  otherItem.selected = true;
              });
            }
            if (item.idTemplate === response.healthUnitAdminStruct.idSadtTemplate) {
              this.listTemplate.forEach((otherItem: TemplateStruct) => {
                if (otherItem.idReportType === ReportTypeEnum.SADT && otherItem.idTemplate !== response.healthUnitAdminStruct.idSadtTemplate)
                  otherItem.disabled = true;

                if (otherItem.idReportType === ReportTypeEnum.SADT && otherItem.idTemplate === response.healthUnitAdminStruct.idSadtTemplate)
                  otherItem.selected = true;
              });
            }
          });
        }

        this.verifyRequiredDomain();

        if (response.healthUnitAdminStruct.idLoginType == HealthUnitLoginTypeEnum.google
          || response.healthUnitAdminStruct.idLoginType == HealthUnitLoginTypeEnum.office365) {

          let listEmailDomainString = response.listEmailDomain[0].domainName;
          for (let i = 1; i < response.listEmailDomain.length; i++) {
            listEmailDomainString = listEmailDomainString + `,${response.listEmailDomain[i].domainName}`;
          }
          this.model.get('emailDomain').setValue(listEmailDomainString);
        }

        if (response.healthUnitAdminStruct.idProcedureSigtapCompetence)
          this.model.get('idProcedureSigtapCompetence').setValue(response.healthUnitAdminStruct.idProcedureSigtapCompetence.toString());

        this.logo = response.healthUnitAdminStruct.healthUnitLogo;

        this.model.get('firstChildGroup').get('idHealthUnitState').setValue(response.healthUnitAdminStruct.idHealthUnitState);

        if (response.healthUnitAdminStruct.idHealthUnitCity)
          this.isSelectCity = true;

        this.model.get('firstChildGroup').get('idHealthUnitCity').setValue(response.healthUnitAdminStruct.idHealthUnitCity);

        this.model.get('cityIbgeCode').setValue(response.healthUnitAdminStruct.codIbge);
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateModuleSelect() {
    this.listAdminModuleService.listModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listModule = response.listModuleStruct;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitGroupSelect() {
    this.healthUnitGroupAdminService.ListHealthUnitGroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnitGroup = response.listHealthUnitGroup;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateTimeZoneSelect() {
    this.timeZoneService.listTimeZone().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listTimeZone = response.listTimeZone;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  ConfirmTemplateChange() {
    this.dialog.open(TemplateBiConfirmModalComponent, {});
  }

  populateLoginTimeoutSelect() {
    this.loginTimeoutService.listLoginTimeout().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listLoginTimeout = response.listLoginTimeout;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateProtocolMultiSelect() {
    this.adminProtocolService.listProtocol(null, true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProtocol = response.listProtocol;

        this.listTemplateProtocol = response.listProtocol.filter(x => x.isTemplate);

        if (!this.idHealthUnit || !this.listProtocolAux || this.listProtocolAux.length == 0)
          this.model.get('listIdProtocol').setValue(this.listTemplateProtocol.map(c => c.idProtocol.toString()));
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listHealthUnitProtocol() {
    this.adminProtocolService.listHealthUnitProtocol(this.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listProtocol && response.listProtocol.length > 0)
          this.model.get('listIdProtocol').setValue(response.listProtocol.map(c => c.idProtocol.toString()));

        this.listProtocolAux = response.listProtocol;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateEvasionTimeSelect() {
    this.evasionTimeService.listEvasionTime().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listEvasionTime = response.listEvasionTime;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBillingSigtapSelect() {
    this.competenceSelectService.listProcedureSigtapCompetenceSelect().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listCompetenceSelectStruct = response.procedureSigtapCompetences;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateLookupSelect() {
    this.billingLookupService.listLookup().subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1)
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listCoordination = response.listCoordination;
        this.listSupervision = response.listSupervision;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateHealthUnit(healthUnitAdminRequest: HealthUnitAdminRequest) {
    this.postHealthUnitAdminService.updateHealthUnit(this.idHealthUnit, healthUnitAdminRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.createHealthUnitProtocol();       
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createHealthUnit(healthUnitAdminRequest: HealthUnitAdminRequest) {
    this.postHealthUnitAdminService.createHealthUnit(healthUnitAdminRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.idHealthUnit = response.idHeathUnit;
        this.createHealthUnitProtocol();    
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createHealthUnitProtocol() {
    let healthUnitProtocolRequest = new HealthUnitProtocolRequest();
    healthUnitProtocolRequest.idHealthUnit = this.idHealthUnit;
    healthUnitProtocolRequest.listIdProtocol = this.model.get('listIdProtocol').value;

    this.healthUnitProtocolService.createHealthUnitProtocol(healthUnitProtocolRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }

        if (this.model.get('isBillingSigtap').value)
          this.saveHealthUnitData();
        else {
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false;

          this.router.navigate(['/consulting/health-unit']);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  saveHealthUnitData() {
    let healthUnitDataRequest = new HealthUnitDataRequest();
    healthUnitDataRequest.idHealthUnit = this.idHealthUnit;
    healthUnitDataRequest.cnes = this.model.get('cnes').value ? this.model.get('cnes').value : null;
    healthUnitDataRequest.idCoordination = this.model.get('coordination').value;
    healthUnitDataRequest.establishment = this.model.get('healthUnitName').value;
    healthUnitDataRequest.idSupervision = this.model.get('supervision').value;

    this.healthUnitDataService.saveHealthUnitData(healthUnitDataRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/health-unit']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(HealthUnitLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.newLogo)
        this.newLogo = result.newLogo;

      if (result && !result.haveLogo)
        this.model.get('haveLogo').setValue(false);
    });
  }

  passwordValidation(control: AbstractControl) {
    (1)
    let email = control.value; (2)
    if (email && email.indexOf("@") != -1) {
      (3)
      let [_, domain] = email.split("@"); (4)
      if (domain !== "codecraft.tv") {
        (5)
        return {
          emailDomain: {
            parsedDomain: domain
          }
        }
      }
    }
    return null; (6)
  }

  checkBilling(event) {
    if (event.checked) {
      this.model.get('cnes').setValidators([Validators.required]);
      this.model.get('cnes').updateValueAndValidity();
      this.model.get('coordination').setValidators([Validators.required]);
      this.model.get('coordination').updateValueAndValidity();
      this.model.get('supervision').setValidators([Validators.required]);
      this.model.get('supervision').updateValueAndValidity();
      this.model.get('idProcedureSigtapCompetence').setValidators([Validators.required]);
      this.model.get('idProcedureSigtapCompetence').updateValueAndValidity();
      this.model.get('cityIbgeCode').setValidators([Validators.required]);
      this.model.get('cityIbgeCode').updateValueAndValidity();
    }
    else {
      this.model.get('cnes').setValidators(null);
      this.model.get('cnes').updateValueAndValidity();
      this.model.get('coordination').setValidators(null);
      this.model.get('coordination').updateValueAndValidity();
      this.model.get('supervision').setValidators(null);
      this.model.get('supervision').updateValueAndValidity();
      this.model.get('idProcedureSigtapCompetence').setValidators(null);
      this.model.get('idProcedureSigtapCompetence').updateValueAndValidity();
      this.model.get('cityIbgeCode').setValidators(null);
      this.model.get('cityIbgeCode').updateValueAndValidity();
    }
  }

  selectState(event) {
    this.isSelectCity = true;
    this.model.get('firstChildGroup').get('idHealthUnitState').setValue(event.idState)
    this.urlListCity = environment.urlApiUser + `HealthUnitCity/idHealthUnitState/${event.idState}`;
  }

  selectCity(event) {
    this.isSelectCity = true;
    this.model.get('firstChildGroup').get('idHealthUnitCity').setValue(event.idCity)
    this.model.get('cityIbgeCode').setValue(event.codIbge)
  }

  private timeoutKeySearch: any = null;

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCEP(event.target.value);
    }, 1000);
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        if (!response.logradouro) {
          this.alertService.show('Erro', "Não há logradouro cadastrado para o CEP inserido", AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        this.getStateByUf(response.uf, response.localidade);
        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('country').setValue("Brasil");
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getStateByUf(stateUf: string, city: string) {
    this.healthUnitStateService.getStateByInitials(stateUf).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        this.model.get('firstChildGroup').get('idHealthUnitState').setValue(response.list[0].idState);
        let stateObj = {
          idState: response.list[0].idState,
          stateName: response.list[0].stateName,
        }

        this.preSelectState = [stateObj];
        this.urlListCity = environment.urlApiUser + `HealthUnitCity/idHealthUnitState/${response.list[0].idState}?searchText=${city}`;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validateEmail() {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (!$this.model.get('healthUnitEmail').value)
        return;

      if (/([\w-]+\.)+[\w-]{2,20}$/.test($this.model.get('healthUnitEmail').value))
        return (true);

      $this.alertService.show('Aviso', "Endereço de email inválido", AlertType.warning);
      return (false);
    }, 1000);
  }

  getPasswordExpirationTime() {
    this.passwordExpirationTimeService.listPasswordExpirationTime().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listPasswordExpirationTime = response.listPasswordExpirationTime;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatUserLookupSelect() {
    this.lookupService.listAllLookup().subscribe({
      next: (response: GetAllLookupResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (!response.listLoginType || response.listLoginType.length == 0 || !response.listUserExpirationTime || response.listUserExpirationTime.length == 0) {
          this.alertService.show('Erro', "Erro na busca da lista de tipos de login ou na lista de tempo de expiração do usuário", AlertType.error);
          return;
        }

        this.listLoginType = response.listLoginType;
        this.listUserExpirationTime = response.listUserExpirationTime;
        this.listProfession = response.listProfession;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyRequiredDomain() {
    let idLoginType = this.model.get('idLoginType').value;
    if (idLoginType == HealthUnitLoginTypeEnum.user) {
      this.disabledFieldEmailDomain = true;
      this.model.get('emailDomain').disable();
      this.model.get('emailDomain').setValidators([]);
      this.model.get('emailDomain').updateValueAndValidity();
    }
    else {
      this.disabledFieldEmailDomain = false;
      this.model.get('emailDomain').enable();
      this.model.get('emailDomain').setValidators([Validators.required]);
      this.model.get('emailDomain').updateValueAndValidity();
    }
  }

  validateEmailAllowed() {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (!$this.model.get('emailDomain').value)
        return;

      if (/([\w-]+\.)+[\w-]{2,20}$/.test($this.model.get('emailDomain').value))
        return (true);

      $this.alertService.show('Aviso', "Endereço de email inválido", AlertType.warning);
      return (false);
    }, 1000);
  }

  populateSelectUserWithMasterModuleAccess(idHealthUnit) {
    this.userService.getUserWithAccessInMasterModule(idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listUserWithAccessInMasterModule = response.listUser;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openChangeMasterUserModal() {
    const dialogRef = this.dialog.open(ChangeMasterUserModalComponent, {
      data: {
        idNewUserMaster: this.model.get('idNewUserMaster').value,
        idOldMasterUser: this.idMasterUser,
        idHealthUnit: this.idHealthUnit,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.changeMasterUser)
        window.location.reload();

      if (result && !result.changeMasterUser)
        this.model.get('idNewUserMaster').setValue('');
    });
  }

  hideOrNotUserMasterSelect() {
    if (this.listUserWithAccessInMasterModule != null && this.listUserWithAccessInMasterModule.length == 0) {
      this.alertService.show('Alerta', "não existe nenhum usuário com funcionalidade do módulo master.", AlertType.warning);
      this.isLoading = false;
      return;
    }

    this.hideUserMasterSelect = !this.hideUserMasterSelect;
  }

  checkICPBrasil(event) {
    if (event.checked)
      this.model.get('a3Quantity').setValidators([Validators.required]);
    else {
      this.model.get('a3Quantity').setValidators(null);
      this.model.get('a3Quantity').setValue(null);
    }

    this.model.get('a3Quantity').updateValueAndValidity();
  }

  toggleTemplateSelection(template: any) {
    if (template.disabled) {
      this.alertService.show('Alerta', "Já tem uma opção selecionada desse tipo de Report.", AlertType.warning);
      return;
    }

    this.listTemplate.forEach((item: TemplateStruct) => {
      if (item.idReportType === template.idReportType) {
        if (item.idTemplate === template.idTemplate) {
          if (item.disabled || item.selected) {
            // Desbloquear os outros idTemplates do mesmo idReportType
            this.listTemplate.forEach((otherItem: TemplateStruct) => {
              if (otherItem.idReportType === template.idReportType)
                otherItem.disabled = false;

              if (otherItem.idTemplate === template.idTemplate)
                otherItem.selected = false;
            });
          }
          else {
            // Bloquear os outros idTemplates do mesmo idReportType
            this.listTemplate.forEach((otherItem: TemplateStruct) => {
              if (otherItem.idReportType === template.idReportType && otherItem.idTemplate !== template.idTemplate)
                otherItem.disabled = true;

              if (otherItem.idReportType === template.idReportType && otherItem.idTemplate === template.idTemplate)
                otherItem.selected = true;
            });
          }
        }
      }
    });
  }

  populateGroupOriginSelect() {
    this.groupOriginService.listGroupOrigin().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listGroupOrigin = response.listGroupOrigin;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyHealthUnitsHasIntegration(verifyHealthUnitHasIntegrationRequest: VerifyHealthUnitHasIntegrationRequest, healthUnitAdminRequest: HealthUnitAdminRequest){
    this.healthUnitIntegrationRelationConfigService.VerifyHealthUnitsHasIntegration(verifyHealthUnitHasIntegrationRequest).subscribe({
        next: (response) => {                            
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          if (response.listHealthUnitRelation && response.listHealthUnitRelation.length > 0){

            let bodyErros: string = `Atenção! A unidade está integrada com outro sistema. Por favor, revise a integração e inclua os novos campos necessários.`;
                      
            this.openGenericErrorMessageModal(bodyErros, healthUnitAdminRequest);
          } else{
            this.updateHealthUnit(healthUnitAdminRequest);
          }
             
        },
        error: (error) => {
            console.log(error)
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
        }
    });
  }

  openGenericErrorMessageModal(body: string, healthUnitAdminRequest: HealthUnitAdminRequest){
    const dialogRef = this.dialog.open(GenericAlertModalComponent, {
      data: {
        body: body
      },
      panelClass: 'generic-error',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.updateHealthUnit(healthUnitAdminRequest);
    });
  }
}