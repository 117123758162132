import { HealthUnitTemplate } from "../../models/report/health-unit-template.model";
import { EmailDomain } from "../../models/user/email-domain.model";
import { TemplateStruct } from "../../structs/report/template.struct";

export class HealthUnitAdminRequest {
    public newHealthUnitLogo: string;
    public haveLogo: boolean;
    public idTimeZone: number;
    public idEvasionTime: number;
    public idLoginTimeout: number;
    public idCity: number;
    public idHealthUnitGroup: number;
    public healthUnitName: string;
    public cnes: string;
    public idSupervision: number;
    public idCoordination: number;
    public isBillingSigtap: boolean;
    public isHealthUnitGroupMaster: boolean;
    public idProcedureSigtapCompetence: number;
    public codIbge: string;
    public hasFlowchartIA: boolean;
    public zipCode: string;
    public street: string;
    public houseNumber: number;
    public addressSupplement: string;
    public neighborhood: string;
    public country: string;
    public healthUnitEmail: string;
    public idPasswordExpirationTime: number;
    public idLoginType: number;
    public listEmailDomain: EmailDomain[] = [];
    public hasICPBrasil: boolean;
    public a3Quantity: number;
    public isHealthUnitBlocked: boolean;
    public canReturnToQueue: boolean;
    public idTemplateBi: number = null;
    public idGroupOrigin: number = null;
    public idUserExpirationTime: number = null;

    //Usuario Master da unidade
    public userMasterName: string;
    public email: string;
    public password: string;
    public login: string;
    public phone: string;
    public cpf: string;
    public idUserMaster: number;
    public idProfession: number;

    //Modulos da unidade
    public listIdModule: number[];

    //Template
    public idSadtTemplate: number;
    public healthUnitTemplateForAdminReport: HealthUnitTemplate;
    public isTemplateForAdminReportUpdate: boolean;
    public listIdTemplate: number[];
    public listTemplate: TemplateStruct[];
}