import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.phoneSac = "0000 000 0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.creditCardNumber = "0000 0000 0000 0000,";
    masks.date = "00/00/0000";
    masks.monthYear = "00/0000";
    masks.age = { mask: "000L00L00L", pattern: "^([0-9]{3}a(0[0-9]|1[01])m((0|1|2)[0-9])d)$" };
    masks.temperature = "00,0";
    masks.pressure = "000/000";
    masks.threeDigits = "000";
    masks.height = "0,00";
    masks.cnes = "0000000";
    masks.datetime = "00/00/0000 00:00:00";
    return masks;
  }

  clearMaskPhone(val: string): string {
    return val.replace('(', '').replace(')', '').replace(' ', '');
  }

  clearMaskCpf(val: string): string {
    return val.replace('.', '').replace('.', '').replace('-', '');
  }

  clearMaskCreditCardNumber(val: string): string {
    return val.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '');
  }

  clearMaskDate(val: string): Date {
    let aux: string[] = val.split('/');
    return new Date(aux[2] + ' ' + aux[1] + ' ' + aux[0]);
  }
}

export class Masks {
  phone;
  phoneSac;
  cpf;
  cnpj;
  creditCardNumber;
  date;
  temperature;
  pressure;
  threeDigits;
  age;
  height;
  monthYear;
  cnes;
  datetime;
}