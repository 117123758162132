import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirstAccessModalComponent } from './first-access-modal/first-access-modal.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,    
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute) { }
    
  public model: FormGroup;
  public socialUser!: SocialUser;
  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.auth;
  public returnUrl = null;
  
  ngOnInit(): void {
    //this.alertService.show("Titulo", "Production: " + environment.production + " - Staging: " + environment.staging , AlertType.success);

    this.isLoading = false;

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.socialGoogle();

    this.activatedRoute.queryParams.subscribe(params => { 
      this.returnUrl = params.returnUrl;
      console.log(params);
    });
  }

  socialGoogle(){
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      if(this.socialUser)
        this.singinWithGoogle(this.socialUser.idToken);
      
    });
    
  }


  singin() {
    if (this.model.invalid  || this.isLoading)
      return;
    this.isLoading = true;

    this.authService.signIn(this.model.get('login').value, this.model.get('password').value, '192.168.0.2').subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      if (response.isFirstAccess) {
        this.openModal(this.model.get('login').value);
        this.isLoading = false;
        return;
      }
        
      else {
        this.authService.updateToken(response);

        this.isLoading = false;
        
        this.redirectUser();
      }
    },
    (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    });
  }

  singinWithGoogle(token: string) {
    if (this.isLoading){
      return;
    }
    this.isLoading = true;

    this.authService.signInWithGoogle(token, '192.168.0.2').subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
        
      else {
        this.authService.updateToken(response);

        this.isLoading = false;
        
        this.redirectUser();
      }
    },
    (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    });
  }

  openModal(userLogin: string) {
    const dialogRef = this.dialog.open(FirstAccessModalComponent, {
      data: {
        userLogin: userLogin
      },
    });
  }

  redirectUser(){
    if(this.returnUrl)
      this.router.navigate(['/' + this.returnUrl]);
    else
      this.router.navigate(['/welcome']);
  }

}
