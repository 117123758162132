import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchConfigurationVisualizerService } from 'src/app/shared/services/API/srvlog/batch-configuration-visualizer.service';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ListBatchConfigurationStruct } from 'src/app/shared/services/structs/srvlog/list-batch-configuration.struct';
import { BatchDeleteModalComponent } from './batch-delete-modal/batch-delete-modal.component';
import { BatchRunModalComponent } from './batch-run-modal/batch-run-modal.component';

@Component({
  selector: 'app-batch-config-list',
  templateUrl: './batch-config-list.component.html',
  styleUrls: ['./batch-config-list.component.css']
})
export class BatchConfigListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private batchConfigurationVisualizerService: BatchConfigurationVisualizerService,
    private dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.developer_batch_configuration;

  public isLoading: boolean;
  public listBatch: ListBatchConfigurationStruct[];

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.listBatch().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listBatch = response.listBatchConfiguration;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idBatch) {
    const dialogRef = this.dialog.open(BatchDeleteModalComponent, {
      data: {
        idBatch: idBatch
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteBatch) {
        this.search();
      }
    });
  }

  openRunModal(idBatch) {
    const dialogRef = this.dialog.open(BatchRunModalComponent, {
      data: {
        idBatch: idBatch
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.runBatch) {
        this.search();
      }
    });
  }
}