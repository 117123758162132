import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PowerBiService } from 'src/app/shared/services/API/business-intelligence/power-bi.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-report-confirm-refresh-modal',
  templateUrl: './report-confirm-refresh-modal.component.html',
  styleUrls: ['./report-confirm-refresh-modal.component.css']
})

export class ReportConfirmRefreshModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportConfirmRefreshModalComponent>,
    private PowerBiService: PowerBiService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  clickRefresh() {

    this.isLoading = true;
    let isRefreshFull = true;

    this.PowerBiService.Refresh(this.data.idReport, isRefreshFull).subscribe((response) => {

        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Processo Iniciado', "Relatório em atualização!", AlertType.success); 
        this.matDialogRef.close(); 
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
      this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}
