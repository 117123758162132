<header>
    <div class="header-logo" (click)="redirect(uriModuleSelectd)">
        <a class="link-menu">
           
            <img class="simple-brand" src="assets/images/simple-logo-branco.png">
        </a>
        <h1 class="module-name" >{{selectedModuleName}}</h1>
    </div>


    <div class="user-actions" *ngIf="isAuthenticated == true">
        <mat-icon aria-hidden="false" aria-label="Menu apps" (click)="openModuleInfo()">apps</mat-icon>
        <a class="link-name" (click)="openUserInfo()">{{initials}}</a>
    </div>
</header>

<div *ngIf="!onlineOffline" class="offline">
    <mat-icon class="offline--icon">wifi_off</mat-icon>
    <span>Você esta sem conexão com a internet no momento.</span>
</div>

<nav *ngIf="isRenderMenu != false">
    <ul *ngIf="systemMainMenu != null">
        <li *ngFor="let item of systemMainMenu | slice:0:3">
            <a (click)="selectItem(item)" [ngClass]="{'active': item.active}">
                <mat-icon aria-hidden="false" aria-label="Menu apps">{{item.iconClass}}</mat-icon>
                <span>{{item.name}}</span>
            </a>
        </li>

        <li *ngIf="systemMainMenu.length > 3" class="menu-more-mobile">
            <a (click)="toggleMenuMobile()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">more_horiz</mat-icon>
                <span>Mais</span>
            </a>
        </li>
    </ul>

    <div class="plus-menu-mobile active" [ngClass]="{'active': isMenuMobileOpen}">
        <span (click)="closeMenuMobile()">Fechar</span>
        <ul *ngIf="systemMainMenu.length > 3">
            <li *ngFor="let item of systemMainMenu | slice:3">
                <a (click)="selectItem(item)" [ngClass]="{'active': item.active}">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">{{item.iconClass}}</mat-icon>
                    <span>{{item.name}}</span>
                </a>
            </li>
        </ul>
    </div>
</nav>
<app-secundary-header *ngIf="hasSecundaryHeader" class="secundary-header" [episode]="episode" [discriminators]="discriminators"
    [hasCachedModule]="hasCachedModule" [cachedModuleName]="cachedModuleName" [painColorCode]="painColorCode"
    [gravityColorCode]="gravityColorCode" [pain]="pain" [gravity]="gravity" [isClassification]="isClassification">
</app-secundary-header>
<div class="container-out" (click)="closeMenuMobile()" *ngIf="isMenuMobileOpen == true"></div>

<app-user-infos *ngIf="isAuthenticated == true" [isVisible]="isVisibleUserInfo" (closeUserInfo)=closeUserInfo()>
</app-user-infos>
<app-module-infos *ngIf="isAuthenticated == true" [isVisible]="isVisibleModule" [menuModuleEnum]="this.menuModuleEnum"
    (closeModuleInfo)=closeModuleInfo()></app-module-infos>
<app-sub-menu *ngIf="isAuthenticated == true && isRenderMenu != false" [isVisible]="isVisibleSubMenu"
    (closeSubMenu)="closeSubMenu()" [actualMainMenuName]="openedMainMenu" [listSubMenuStruct]="selectedSubMenu">
</app-sub-menu>