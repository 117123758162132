import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorRequest } from 'src/app/shared/services/requests/risk-classification/discriminator.request';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';

@Component({
  selector: 'app-discriminator-add-modal',
  templateUrl: './discriminator-add-modal.component.html',
  styleUrls: ['./discriminator-add-modal.component.css']
})
export class DiscriminatorAddModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<DiscriminatorAddModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private discriminatorService: DiscriminatorService
  ) { }

  public isLoading: boolean;
  public modelDiscriminator: FormGroup;
  public masks: Masks;
  public preSelectCounseling: CounselingModel[] = [];
  private selectedCounseling: CounselingModel[] = [];
  public listDiscriminator: DiscriminatorStruct[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;
    this.modelDiscriminator = this.formBuilder.group({
      name: ['', [Validators.required]],
      minAge: [''],
      maxAge: [''],
      order: [null, [Validators.required]],
      description: [''],
      idDiscriminatorType: ['', [Validators.required]],
      minValueAllowed: [''],
      maxValueAllowed: [''],
      minValue2Allowed: [''],
      maxValue2Allowed: [''],
      isRequired: [true, [Validators.required]],
    });
    this.listDiscriminator = this.data.listDiscriminator;
    if (this.data.discriminator) {
      this.populateModel(this.data.discriminator);
    }
  }

  populateModel(discriminator: DiscriminatorStruct) {
    this.modelDiscriminator.get("name").setValue(discriminator.discriminatorName);
    if (!this.IsUndefined(discriminator.minAgeStruct)) {
      this.modelDiscriminator.get("minAge").setValue(this.getAgeFormatted(discriminator.minAgeStruct));
    }
    if (!this.IsUndefined(discriminator.maxAgeStruct)) {
      this.modelDiscriminator.get("maxAge").setValue(this.getAgeFormatted(discriminator.maxAgeStruct));
    }
    this.modelDiscriminator.get("order").setValue(discriminator.order);
    this.modelDiscriminator.get("description").setValue(discriminator.description);
    this.modelDiscriminator.get("idDiscriminatorType").setValue(discriminator.idDiscriminatorType);
    this.modelDiscriminator.get("minValueAllowed").setValue(discriminator.minValueAllowed);
    this.modelDiscriminator.get("maxValueAllowed").setValue(discriminator.maxValueAllowed);
    this.modelDiscriminator.get("minValue2Allowed").setValue(discriminator.minValue2Allowed);
    this.modelDiscriminator.get("maxValue2Allowed").setValue(discriminator.maxValue2Allowed);
    this.modelDiscriminator.get("isRequired").setValue(discriminator.isRequired);
    this.preSelectCounseling = discriminator.listCounseling;
    this.selectedCounseling = [...discriminator.listCounseling]
  }

  IsUndefined(age: AgeStruct): boolean {
    if (!age) {
      return true;
    }
    let yearNull: boolean = age.years === null || age.years === undefined;
    let monthNull: boolean = age.months === null || age.months === undefined;
    let dayNull: boolean = age.days === null || age.days === undefined;

    return yearNull && monthNull && dayNull
  }

  submitDiscriminator() {
    if (this.modelDiscriminator.invalid) {
      this.alertService.show("Erro", "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let isValid: boolean = this.validateOrder();

    if (!isValid) {
      return;
    }

    let discriminator: DiscriminatorStruct = new DiscriminatorStruct();
    discriminator.order = this.modelDiscriminator.get("order").value;
    discriminator.minAgeStruct = this.formatDateToSave(this.modelDiscriminator.get("minAge").value);
    discriminator.maxAgeStruct = this.formatDateToSave(this.modelDiscriminator.get("maxAge").value);
    discriminator.idFlowchart = this.data.idFlowchart;
    discriminator.discriminatorName = this.modelDiscriminator.get("name").value;
    discriminator.description = this.modelDiscriminator.get("description").value;
    discriminator.idDiscriminatorType = this.modelDiscriminator.get("idDiscriminatorType").value;
    discriminator.minValueAllowed = this.modelDiscriminator.get("minValueAllowed").value ? parseFloat(this.modelDiscriminator.get("minValueAllowed").value) : null;
    discriminator.maxValueAllowed = this.modelDiscriminator.get("maxValueAllowed").value ? parseFloat(this.modelDiscriminator.get("maxValueAllowed").value) : null;
    discriminator.minValue2Allowed = this.modelDiscriminator.get("minValue2Allowed").value ? parseFloat(this.modelDiscriminator.get("minValue2Allowed").value) : null;
    discriminator.maxValue2Allowed = this.modelDiscriminator.get("maxValue2Allowed").value ? parseFloat(this.modelDiscriminator.get("maxValue2Allowed").value) : null;
    discriminator.listCounseling = this.selectedCounseling;
    discriminator.idCounseling = this.selectedCounseling.map(c => c.idCounseling);
    discriminator.isRequired = this.modelDiscriminator.get("isRequired").value;
    discriminator.idPriorityColor = this.data.idPriorityColor;

    if (this.data.discriminator) {
      discriminator.idDiscriminator = this.data.discriminator.idDiscriminator;
      this.UpdateDiscriminator(discriminator);
    }
    else {
      this.CreateDiscriminator(discriminator);
    }
  }

  validateOrder(): boolean {
    let isValid: boolean = true;
    let discriminator: DiscriminatorStruct = new DiscriminatorStruct();
    discriminator.idDiscriminator = this.data.discriminator ? this.data.discriminator.idDiscriminator : null
    discriminator.order = this.modelDiscriminator.get("order").value;
    discriminator.minAgeStruct = this.formatDateToSave(this.modelDiscriminator.get("minAge").value);
    discriminator.maxAgeStruct = this.formatDateToSave(this.modelDiscriminator.get("maxAge").value);

    let incomparable: boolean = false;
    let intersectionUpperBound: boolean = false;
    let intersectionLowerBound: boolean = false;

    isValid = !this.listDiscriminator.some((x: DiscriminatorStruct, index) => {
      let invalid: boolean = false;
      if (x.order == discriminator.order && discriminator.idDiscriminator != x.idDiscriminator) {
        incomparable = (this.IsUndefined(x.maxAgeStruct) && this.IsUndefined(x.minAgeStruct));

        if (incomparable) {
          invalid = true;

          this.modelDiscriminator.get('order').setErrors({
            incomparable: true
          });

          this.modelDiscriminator.get('minAge').setErrors(null);
          this.modelDiscriminator.get('maxAge').setErrors(null);

          this.modelDiscriminator.get('minAge').markAsTouched();
          this.modelDiscriminator.get('maxAge').markAsTouched();
          this.modelDiscriminator.get('order').markAsTouched();

          return invalid;
        }

        else if ((this.IsUndefined(discriminator.maxAgeStruct) || this.IsUndefined(discriminator.minAgeStruct))) {
          invalid = true;

          if (this.IsUndefined(discriminator.maxAgeStruct)) {
            this.modelDiscriminator.get('maxAge').setErrors({
              undefined: true
            });

            this.modelDiscriminator.get('maxAge').markAsTouched();
          }

          if (this.IsUndefined(discriminator.minAgeStruct)) {
            this.modelDiscriminator.get('minAge').setErrors({
              undefined: true
            });

            this.modelDiscriminator.get('minAge').markAsTouched();
          }

          return invalid;
        }

        else {
          intersectionUpperBound = this.IsLesserOrEqual(x.minAgeStruct, discriminator.maxAgeStruct) && this.IsLargerOrEqual(x.maxAgeStruct, discriminator.maxAgeStruct)
          intersectionLowerBound = this.IsLesserOrEqual(x.minAgeStruct, discriminator.minAgeStruct) && this.IsLargerOrEqual(x.maxAgeStruct, discriminator.minAgeStruct) ;

          if (intersectionUpperBound || intersectionLowerBound) {
            invalid = true;

            if (intersectionUpperBound) {
              this.modelDiscriminator.get('maxAge').setErrors({
                intersection: true
              });
            }

            if (intersectionLowerBound) {
              this.modelDiscriminator.get('minAge').setErrors({
                intersection: true
              });
            }
            this.modelDiscriminator.get('minAge').markAsTouched();
            this.modelDiscriminator.get('maxAge').markAsTouched();
            return invalid;
          }

          else {
            this.modelDiscriminator.get('minAge').setErrors(null);
            this.modelDiscriminator.get('maxAge').setErrors(null);
            this.modelDiscriminator.get('order').setErrors(null);

            this.modelDiscriminator.get('minAge').markAsTouched();
            this.modelDiscriminator.get('maxAge').markAsTouched();
            this.modelDiscriminator.get('order').markAsTouched();
          }
        }
      }
    });

    if (isValid) {
      this.modelDiscriminator.get('minAge').setErrors(null);
      this.modelDiscriminator.get('maxAge').setErrors(null);
      this.modelDiscriminator.get('order').setErrors(null);

      this.modelDiscriminator.get('minAge').markAsTouched();
      this.modelDiscriminator.get('maxAge').markAsTouched();
      this.modelDiscriminator.get('order').markAsTouched();
    }

    return isValid;
  }

  clickCancel() {
    this.matDialogRef.close(null);
  }

  close() {
    this.matDialogRef.close(null);
  }

  validateAge(value: string, event: any) {
    if (value) {
      let result = this.formatDateToSave(value);
      if (result.months > 12) {
        this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 12", AlertType.error);
      } else if (result.days > 29) {
        this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 29", AlertType.error);
      }
    }
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  selectCounseling(event: any) {
    this.selectedCounseling = [...event];
  }

  //Retorna se o primeiro argumento é menor que o segundo
  public IsLesserOrEqual(a: AgeStruct, b: AgeStruct): boolean {
    if (a.years < b.years) {
      return true;
    }
    else if (a.years == b.years) {

      if (a.months < b.months) {
        return true;
      }

      else if (a.months == b.months) {

        if (a.days < b.days) {
          return true;
        }

        else if (a.days == b.days) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false
      }
    }
    else {
      return false;
    }
  }

  //Retorna se o primeiro argumento é maior que o segundo
  public IsLargerOrEqual(a: AgeStruct, b: AgeStruct): boolean {
    if (a.years > b.years) {
      return true;
    }
    else if (a.years == b.years) {

      if (a.months > b.months) {
        return true;
      }

      else if (a.months == b.months) {

        if (a.days > b.days) {
          return true;
        }

        else if (a.days == b.days) {
          return true;
        }

        else {
          return false;
        }
      }
      else {
        return false
      }
    }
    else {
      return false;
    }
  }

  CreateDiscriminator(discriminatorStruct: DiscriminatorStruct) {
    this.isLoading = true;
    let request: DiscriminatorRequest = new DiscriminatorRequest();
    request.discriminator = discriminatorStruct;

    this.discriminatorService.CreateDiscriminator(request).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        discriminatorStruct.idDiscriminator = response.idDiscriminator;
        this.matDialogRef.close(discriminatorStruct);
        this.alertService.show('Sucesso!', 'Discriminador criado com sucesso', AlertType.success);
      },

      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close();
      }
    });
  }

  UpdateDiscriminator(discriminatorStruct: DiscriminatorStruct) {
    this.isLoading = true;
    let request: DiscriminatorRequest = new DiscriminatorRequest();
    request.discriminator = discriminatorStruct;

    this.discriminatorService.UpdateDiscriminator(request).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.matDialogRef.close(discriminatorStruct);
        this.alertService.show('Sucesso!', 'Discriminador atualizado com sucesso', AlertType.success);
      },

      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}