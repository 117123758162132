import { AfterContentInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { FlowchartGroupService } from 'src/app/shared/services/API/risk-classification/flowchart-group.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorTypeModel } from 'src/app/shared/services/models/risk-classification/discriminator-type.model';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { FlowchartType } from 'src/app/shared/services/models/risk-classification/flowchart-type.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { PriorityColorGroupModel } from 'src/app/shared/services/models/risk-classification/priority-color.model copy';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { CompleteFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/complete-flowchart.struct';
import { ProtocolStruct } from 'src/app/shared/services/structs/risk-classification/protocol.struct';

@Component({
  selector: 'app-protocol-register',
  templateUrl: './protocol-register.component.html',
  styleUrls: ['./protocol-register.component.css']
})
export class ProtocolRegisterComponent implements OnInit, AfterContentInit, OnChanges {

  constructor(
    private protocolService: ProtocolService,
    private lookupService: LookupRiskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private healthUnitService: HealthUnitService,
    private flowchartGroupService: FlowchartGroupService,
    private counselingService: CounselingService
  ) { }


  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean = true;
  public isUpdate: boolean;
  public idProtocol: number;
  public listHealthUnit: ListHealthUnitStruct[];
  public listPriorityColorGroup: PriorityColorGroupModel[];
  public listFlowchart: CompleteFlowchartStruct[] = [];
  public listFlowchartGroup: FlowchartGroupModel[];
  private isTemplate: boolean = false;
  public listPriorityColor: PriorityColorModel[];
  public listAllPriorityColor: PriorityColorModel[];
  public isAfterLoading: boolean;
  public listDiscriminatorType: DiscriminatorTypeModel[];
  public listCounseling: CounselingModel[];
  public listFlowchartType: FlowchartType[];

  ngOnInit(): void {
    this.isAfterLoading = false;
    this.isLoading = false;

    this.idProtocol = null;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idProtocol'))
      this.idProtocol = parseInt(this.activatedRoute.snapshot.paramMap.get('idProtocol'));

    if (this.idProtocol != null) {
      this.isUpdate = true;
      this.populateProtocolData();
    }
    this.getCounselingList();
    this.getLookup();
    this.populateHealthUnitSelect();
    this.getAllFlowchartsGroup();

    this.model = this.formBuilder.group({
      idProtocol: this.idProtocol,
      protocolName: [{ value: '', disabled: this.isUpdate }, [Validators.required]],
      description: [''],
      isTemplate: [{ value: false, disabled: this.isUpdate }],
      isActive: [true],
      isOutOfOrder: [{ value: false, disabled: this.isUpdate }],
      idPriorityColorGroup: [{ value: '', disabled: this.isUpdate }, [Validators.required]],
      isTelephone: [false],
      flowchart: this.formBuilder.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterContentInit() {
    this.isAfterLoading = true;
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    if (this.isTemplate) {
      this.alertService.show('Erro', "Protocolo default não pode ser editado por tela, procure o responsável de TI!", AlertType.error);
      return;
    }

    this.isLoading = true;

    let protocolStruct = new ProtocolStruct;

    protocolStruct.protocolName = this.model.get("protocolName").value;
    protocolStruct.description = this.model.get("description").value;
    protocolStruct.isTemplate = this.model.get("isTemplate").value;
    protocolStruct.isActive = this.model.get("isActive").value;
    protocolStruct.outOfOrder = this.model.get("isOutOfOrder").value;
    protocolStruct.idPriorityColorGroup = this.model.get("idPriorityColorGroup").value;
    protocolStruct.isTelephone = this.model.get("isTelephone").value;
    protocolStruct.listFlowchart = [];
    this.model.get('flowchart').value.forEach((x, i) => {
      var flowchart = new CompleteFlowchartStruct();
      if (x.idFlowchart) {
        flowchart.idFlowchart = x.idFlowchart;
      }
      flowchart.flowchartName = x.name;
      flowchart.description = x.description;
      flowchart.isPunctuationSystem = false;
      flowchart.order = x.order;
      flowchart.flowchartGroup = x.idFlowchartGroup;
      flowchart.minAgeStruct = this.formatDateToSave(x.minAge);
      flowchart.maxAgeStruct = this.formatDateToSave(x.maxAge);
      flowchart.listDiscriminators = this.listFlowchart[i].listDiscriminators;
      flowchart.idFlowchartType = x.idFlowchartType;
      protocolStruct.listFlowchart.push(flowchart);
    });
    if (this.isUpdate)
      this.updateProtocol(protocolStruct);
    else
      this.createProtocol(protocolStruct);
  }


  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }

  populateProtocolData() {
    this.protocolService.GetProtocol(this.idProtocol).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isTemplate = response.protocolStruct.isTemplate;
      this.model.get("protocolName").setValue(response.protocolStruct.protocolName);
      this.model.get("description").setValue(response.protocolStruct.description);
      this.model.get("isTemplate").setValue(response.protocolStruct.isTemplate);
      this.model.get("isActive").setValue(response.protocolStruct.isActive);
      this.model.get("isOutOfOrder").setValue(response.protocolStruct.outOfOrder);
      this.model.get("idPriorityColorGroup").setValue(response.protocolStruct.idPriorityColorGroup);
      this.model.get("isTelephone").setValue(response.protocolStruct.isTelephone);

      this.listFlowchart = response.protocolStruct.listFlowchart;
      response.protocolStruct.listFlowchart.forEach((x) => {
        (this.model.controls['flowchart'] as FormArray).push(
          this.formBuilder.group({
            idFlowchart: [x.idFlowchart],
            name: [x.flowchartName, [Validators.required]],
            minAge: [x.minAge ? this.getAgeFormatted(x.minAgeStruct) : ""],
            maxAge: [x.maxAge ? this.getAgeFormatted(x.maxAgeStruct) : ""],
            order: [x.order, [Validators.required]],
            description: [x.description],
            idFlowchartGroup: [x.flowchartGroup, [Validators.required]],
            idFlowchartType: [x.idFlowchartType, [Validators.required]],
            isFlowchartUpdate: [true],
          })
        )
      });
      if (this.listAllPriorityColor && this.listAllPriorityColor.length > 0) {
        this.listPriorityColor = this.listAllPriorityColor.filter(c => c.idPriorityColorGroup == this.model.get("idPriorityColorGroup").value)
      }
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateProtocol(protocolStruct: ProtocolStruct) {
    this.protocolService.UpdateCompleteProtocol(this.idProtocol, protocolStruct).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
      }
      else {
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/protocol']);
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createProtocol(protocolStruct: ProtocolStruct) {
    this.protocolService.PostCompleteProtocol(protocolStruct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else {
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false;
          this.router.navigate(['/consulting/protocol']);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createSimpleProtocol(protocol: ProtocolModel) {
    this.protocolService.PostSimpleProtocol(protocol).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else {
          this.alertService.show('Sucesso', "Protocolo criado com sucesso", AlertType.success);
          this.idProtocol = response.idProtocol;
          this.model.get('idProtocol').setValue(response.idProtocol);
          this.isUpdate = true;
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listPriorityColorGroup = response.priorityColorGroup;
      this.listAllPriorityColor = response.priorityColors;
      this.listDiscriminatorType = response.discriminatorType;
      this.listFlowchartType = response.listFlowchartType;
      if (this.isUpdate && this.model.get("idPriorityColorGroup").value) {
        this.listPriorityColor = response.priorityColors.filter(c => c.idPriorityColorGroup == this.model.get("idPriorityColorGroup").value)
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
      this.isLoading = false;
      if (!this.isUpdate) {
        this.isFirstLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getAllFlowchartsGroup() {
    this.flowchartGroupService.getFlowchartGroup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listFlowchartGroup = response.flowchartsGroup;
    });
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  updateListColor(idPriorityColorGroup: number) {
    this.listPriorityColor = this.listAllPriorityColor.filter(c => c.idPriorityColorGroup == idPriorityColorGroup);
  }

  getCounselingList() {
    this.counselingService.listCounseling(null).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listCounseling = response.listCounseling;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
