import { AuthGuard } from '../shared/services/auth-guard.service';
import { BillingSigtapListComponent } from './pages/billing-sigtap-list/billing-sigtap-list.component';
import { BillingSigtapRegisterComponent } from './pages/billing-sigtap-register/billing-sigtap-register.component';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { HealthUnitListComponent } from './pages/health-unit-list/health-unit-list.component';
import { HealthUnitRegisterComponent } from './pages/health-unit-register/health-unit-register.component';
import { ProtocolListComponent } from './pages/protocol-list/protocol-list.component';
import { ProtocolRegisterComponent } from './pages/protocol-register/protocol-register.component';
import { TemplateComponent } from './pages/template/template.component';
import { TemplateTypeComponent } from './pages/template-type/template-type.component';
import { TemplateGroupComponent } from './pages/template-group/template-group.component';
import { TemplateSubgroupComponent } from './pages/template-subgroup/template-subgroup.component';
import { TemplateMedicationComponent } from './pages/template-medication/template-medication.component';
import { TemplateRegisterComponent } from './pages/template-register/template-register.component';
import { TemplateSubgroupRegisterComponent } from './pages/template-subgroup-register/template-subgroup-register.component';
import { TemplateTypeRegisterComponent } from './pages/template-type-register/template-type-register.component';
import { TemplateGroupRegisterComponent } from './pages/template-group-register/template-group-register.component';
import { TemplateMedicationRegisterComponent } from './pages/template-medication-register/template-medication-register.component';
import { CounselingListComponent } from './pages/counseling-list/counseling-list.component';
import { CounselingRegisterComponent } from './pages/counseling-register/counseling-register.component';
import { SelfTriageFlowchartListComponent } from './pages/self-triage-flowchart-list/self-triage-flowchart-list.component';
import { SelfTriageFlowchartRegisterComponent } from './pages/self-triage-flowchart-register/self-triage-flowchart-register.component';
import { SelfTriageProtocolListComponent } from './pages/self-triage-protocol-list/self-triage-protocol-list.component';
import { SelfTriageProtocolRegisterComponent } from './pages/self-triage-protocol-register/self-triage-protocol-register.component';
import { SelfTriageCounselingListComponent } from './pages/self-triage-counseling-list/self-triage-counseling-list.component';
import { SelfTriageCounselingRegisterComponent } from './pages/self-triage-counseling-register/self-triage-counseling-register.component';
import { SelfTriageDiscriminatorListComponent } from './pages/self-triage-discriminator-list/self-triage-discriminator-list.component';
import { SelfTriageDiscriminatorRegisterComponent } from './pages/self-triage-discriminator-register/self-triage-discriminator-register.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CoordinationListComponent } from './pages/coordination-list/coordination-list.component';
import { CoordinationRegisterComponent } from './pages/coordination-register/coordination-register.component';
import { SupervisionListComponent } from './pages/supervision-list/supervision-list.component';
import { SupervisionRegisterComponent } from './pages/supervision-register/supervision-register.component';
import { CareLinePriorityListComponent } from './pages/care-line-priority-list/care-line-priority-list.component';
import { CareLinePriorityRegisterComponent } from './pages/care-line-priority-register/care-line-priority-register.component';
import { SuspicionListComponent } from './pages/suspicion-list/suspicion-list.component';
import { SuspicionRegisterComponent } from './pages/suspicion-register/suspicion-register.component';
import { OriginAndArrivalTypeComponent } from './pages/origin-and-arrival-type/origin-and-arrival-type.component';
import { OriginGroupListComponent } from './pages/origin-and-arrival-type/origin-group/origin-group-list.component';
import { OriginGroupRegisterComponent } from './pages/origin-and-arrival-type/origin-group/origin-group-register/origin-group-register.component';
import { HealthGuidelineListComponent } from './pages/health-guideline-list/health-guideline-list.component';
import { HealthGuidelineRegisterComponent } from './pages/health-guideline-register/health-guideline-register.component';
import { FlowchartRegisterComponent } from './pages/protocol-register/flowchart-register/flowchart-register.component';

const routes: Routes = [
  { path: '', component: ConsultingComponent, canActivate: [AuthGuard] },
  { path: 'health-unit', component: HealthUnitListComponent, canActivate: [AuthGuard] },
  { path: 'health-unit/register', component: HealthUnitRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing-sigtap', component: BillingSigtapListComponent, canActivate: [AuthGuard] },
  { path: 'billing-sigtap/register', component: BillingSigtapRegisterComponent, canActivate: [AuthGuard] },
  { path: 'protocol', component: ProtocolListComponent, canActivate: [AuthGuard] },
  { path: 'protocol/register', component: ProtocolRegisterComponent, canActivate: [AuthGuard] },
  { path: 'protocol/flowchart/register', component: FlowchartRegisterComponent, canActivate: [AuthGuard] },
  { path: 'template', component: TemplateComponent, canActivate: [AuthGuard] },
  { path: 'template/register', component: TemplateRegisterComponent, canActivate: [AuthGuard] },
  { path: 'template-type', component: TemplateTypeComponent, canActivate: [AuthGuard] },
  { path: 'template-type/register', component: TemplateTypeRegisterComponent, canActivate: [AuthGuard] },
  { path: 'template-group', component: TemplateGroupComponent, canActivate: [AuthGuard] },
  { path: 'template-group/register', component: TemplateGroupRegisterComponent, canActivate: [AuthGuard] },
  { path: 'template-subgroup', component: TemplateSubgroupComponent, canActivate: [AuthGuard] },
  { path: 'template-medication', component: TemplateMedicationComponent, canActivate: [AuthGuard] },
  { path: 'template-medication/register', component: TemplateMedicationRegisterComponent, canActivate: [AuthGuard] },
  { path: 'template-subgroup/resgister', component: TemplateSubgroupRegisterComponent, canActivate: [AuthGuard] },
  { path: 'counseling', component: CounselingListComponent, canActivate: [AuthGuard] },
  { path: 'counseling/register', component: CounselingRegisterComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-flowchart', component: SelfTriageFlowchartListComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-flowchart/register', component: SelfTriageFlowchartRegisterComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-protocol', component: SelfTriageProtocolListComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-protocol/register', component: SelfTriageProtocolRegisterComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-counseling', component: SelfTriageCounselingListComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-counseling/register', component: SelfTriageCounselingRegisterComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-discriminator', component: SelfTriageDiscriminatorListComponent, canActivate: [AuthGuard] },
  { path: 'self-triage-discriminator/register', component: SelfTriageDiscriminatorRegisterComponent, canActivate: [AuthGuard] },
  { path: 'coordination', component: CoordinationListComponent, canActivate: [AuthGuard] },
  { path: 'coordination/register', component: CoordinationRegisterComponent, canActivate: [AuthGuard] },
  { path: 'supervision', component: SupervisionListComponent, canActivate: [AuthGuard] },
  { path: 'supervision/register', component: SupervisionRegisterComponent, canActivate: [AuthGuard] },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },
  { path: 'care-line-priority/register', component: CareLinePriorityRegisterComponent, canActivate: [AuthGuard] },
  { path: 'care-line-priority', component: CareLinePriorityListComponent, canActivate: [AuthGuard] },
  { path: 'suspicion', component: SuspicionListComponent, canActivate: [AuthGuard] },
  { path: 'suspicion/register', component: SuspicionRegisterComponent, canActivate: [AuthGuard] },
  { path: 'origin-arrival-type', component: OriginAndArrivalTypeComponent, canActivate: [AuthGuard] },
  { path: 'origin-group', component: OriginGroupListComponent, canActivate: [AuthGuard] },
  { path: 'origin-group/register', component: OriginGroupRegisterComponent, canActivate: [AuthGuard] },
  { path: 'health-guideline/list', component: HealthGuidelineListComponent, canActivate: [AuthGuard] },
  { path: 'health-guideline/register', component: HealthGuidelineRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultingRoutingModule { }
