export class FlowchartModel {
    public idFlowchart: number;

    public idProtocol: number;

    public idFlowchartType: number;

    public flowchartName: string;

    public description: string;

    public order: number;

    public isPunctuationSystem: boolean;

    public idGender: number;

    public maxAge: string;

    public minAge: string;

    public datetimeInclusion: Date;
}