import { CommonModule } from '@angular/common';
import { BusinessIntelligenceRoutingModule } from './business-intelligence-routing.module';
import { BusinessIntelligenceComponent } from './pages/business-intelligence/business-intelligence.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';

import { SharedModule } from '../shared/shared.module';
import { ReportRegisterComponent } from './pages/report-register/report-register.component';
import { ReportListComponent } from './pages/report-list/report-list.component';
import { ReportDeleteModalComponent } from './pages/report-list/report-delete-modal/report-delete-modal.component';
import { InternalReportListComponent } from './pages/internal-report-list/internal-report-list.component';
import { InternalReportDeleteModalComponent } from './pages/internal-report-list/internal-report-delete-modal/internal-report-delete-modal.component';
import { InternalReportRegisterComponent } from './pages/internal-report-register/internal-report-register.component';
import { BusinessIntelligenceInternalListComponent } from './pages/business-intelligence-internal-list/business-intelligence-internal-list.component';
import { BusinessIntelligenceInternalDetailsComponent } from './pages/business-intelligence-internal-details/business-intelligence-internal-details.component';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateBusinessIntelligenceListComponent } from './pages/template-business-intelligence-list/template-business-intelligence-list.component';
import { TemplateBusinessIntelligenceRegisterComponent } from './pages/template-business-intelligence-register/template-business-intelligence-register.component';
import { TemplateBusinessIntelligenceDeleteModalComponent } from './pages/template-business-intelligence-list/template-business-intelligence-delete-modal/template-business-intelligence-delete-modal.component';
import { ReportPowerBiModalComponent } from './pages/report-list/report-power-bi-modal/report-power-bi-modal.component';
import { ReportScheduleModalComponent } from './pages/report-list/report-schedule-modal/report-schedule-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { ReportConfirmRefreshModalComponent } from './pages/report-list/report-confirm-refresh-modal/report-confirm-refresh-modal.component';


@NgModule({
  declarations: [
    BusinessIntelligenceComponent, 
    ReportRegisterComponent, 
    ReportListComponent, ReportDeleteModalComponent,
    InternalReportRegisterComponent,
    InternalReportListComponent, InternalReportDeleteModalComponent,
    BusinessIntelligenceInternalListComponent,
    BusinessIntelligenceInternalDetailsComponent,
    TemplateBusinessIntelligenceListComponent,
    TemplateBusinessIntelligenceRegisterComponent,
    TemplateBusinessIntelligenceDeleteModalComponent,
    ReportPowerBiModalComponent,
    ReportScheduleModalComponent,
    ReportConfirmRefreshModalComponent,
  ],
  imports: [
    CommonModule,
    BusinessIntelligenceRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
  ]
})
export class BusinessIntelligenceModule { }
