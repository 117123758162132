
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import {APP_BASE_HREF} from '@angular/common';
import { WelcomeModule } from './welcome/welcome.module';
import { HttpClient } from '@angular/common/http';
import { MasterModule } from './master/master.module';
import { SupportModule } from './support/support.module';
import { ConsultingModule } from './consulting/consulting.module';
import { DeveloperModule } from './developer/developer.module';
import { BusinessIntelligenceModule } from './business-intelligence/business-intelligence.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BillingModule } from './billing/billing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomerSuccessModule } from './customer-success/customer-success.module';

@NgModule({
  declarations: [
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AuthModule,
    WelcomeModule,
    MasterModule,
    SupportModule,
    ConsultingModule,
    DeveloperModule,
    BusinessIntelligenceModule,
    BillingModule,
    BrowserAnimationsModule,
    CustomerSuccessModule
  ],
  providers: [
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
