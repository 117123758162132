<div mat-dialog-content>
    <div class="mat-dialog-content">

        <div class="row">
            <div class="col-md-8">
                <div class="head-totem-modal-list">
                    <h2>Schedule Embedded</h2>
                </div>
            </div>

            <div class="col-md-4">
                <div class="margin-div">
                    <div class="actions2"><a class="border-icon"><mat-icon matTooltip="Normal" aria-hidden="false"
                                aria-label="Schedule" (click)="refreshReport()">restart_alt</mat-icon></a></div>
                    <div class="actions2"><a class="border-icon"><mat-icon matTooltip="Full" aria-hidden="false"
                                aria-label="Schedule" (click)="openModalConfirmRefresh()">update</mat-icon></a></div>
                </div>
            </div>
        </div>


        <div>

            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <p class="header-schedule">Relatório: <span class="report">{{this.data.powerBiReportName}}.pbix</span></p>

                <div class="row">
                    <p><mat-slide-toggle (change)="changeNormalRefresh()" [(ngModel)]="enableNormalRefresh"
                            checked="{{enableNormalRefresh}}"></mat-slide-toggle><mat-label>Habilitar Refresh
                    </mat-label></p>
                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>Data Inicial de Atualização Normal</mat-label>
                            <input matInput type="text" inputmode="datetime-local" placeholder="dd/mm/yyyy hh:mm:ss"
                                formControlName="datetimeInitNormal" [mask]="masks.datetime">
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Periodicidade de Atualização Normal</mat-label>
                            <input matInput type="number" formControlName="updateIntervalNormal">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <p><mat-slide-toggle (change)="changeFullRefresh()" [(ngModel)]="enableFullRefresh"
                            checked="{{enableFullRefresh}}"></mat-slide-toggle><mat-label>Habilitar Refresh
                            Full</mat-label></p>
                </div>


                <div class="row">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>Data Inicial de Atualização Full</mat-label>
                            <input matInput type="text" inputmode="datetime-local" placeholder="dd/mm/yyyy hh:mm:ss"
                                formControlName="datetimeInitFull" [mask]="masks.datetime">
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Periodicidade de Atualização Full</mat-label>
                            <input matInput type="number" formControlName="updateIntervalFull">
                        </mat-form-field>
                    </div>
                </div>




                <div class="row">
                    <div class="col-md-6">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a mat-stroked-button type="button" routerLink="/bi/report" class="btn-secundary btn-block"
                            (click)="clickCancel()">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>