import { MatIconModule } from '@angular/material/icon';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { LoadingListComponent } from './components/loading-list/loading-list.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { MenuComponent } from './menu/menu.component';
import { SubMenuComponent } from './menu/sub-menu/sub-menu.component';
import { UserInfosComponent } from './menu/user-infos/user-infos.component';
import { ModuleInfosComponent } from './menu/module-infos/module-infos.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FilterSelectModalComponent } from './components/filter-select/filter-select-modal/filter-select-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { ChipStatusComponent } from './components/chip/chip-status/chip-status.component';
import { ChipBatchStatusComponent } from './components/chip/chip-batch-status/chip-batch-status.component';
import { BtnReportTemplateComponent } from './components/btn-report-template/btn-report-template.component';
import { BtnReportTemplateListModalComponent } from './components/btn-report-template/btn-report-template-list-modal/btn-report-template-list-modal.component';
import { BtnReportTemplateViewModalComponent } from './components/btn-report-template/btn-report-template-list-modal/btn-report-template-view-modal/btn-report-template-view-modal.component';
import { SelectComponent } from './components/select/select.component';
import { Select2Component } from './components/select/select2/select2.component';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { ChipSigaStatusComponent } from './components/chip/chip-siga-status/chip-siga-status.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivePrincipleTagComponent } from './components/active-principle-tag/active-principle-tag.component';
import { RemoveActivePrincipleModalComponent } from './components/active-principle-tag/remove-active-principle-modal/remove-active-principle-modal.component';
import { GenericAlertModalComponent} from './components/generic-alert-modal/generic-alert-modal.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';


@NgModule({
  declarations: [
    LoadingListComponent,
    LoadingProgressBarComponent,
    MenuComponent,
    SubMenuComponent,
    UserInfosComponent,
    ModuleInfosComponent,
    FilterSelectComponent,
    FilterSelectModalComponent,
    ChipStatusComponent,
    ChipSigaStatusComponent,
    ChipBatchStatusComponent,
    BtnReportTemplateComponent,
    BtnReportTemplateListModalComponent,
    BtnReportTemplateViewModalComponent,
    SelectComponent,
    Select2Component,
    ActivePrincipleTagComponent,
    RemoveActivePrincipleModalComponent,
    GenericAlertModalComponent,
    GaugeChartComponent,
    GenericModalComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatOptionModule,
    MatProgressBarModule,
    MatChipsModule,
    MatDialogModule
  ],
  exports: [
    LoadingListComponent,
    LoadingProgressBarComponent,
    MenuComponent,
    FilterSelectComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    ChipStatusComponent,
    ChipSigaStatusComponent,
    ChipBatchStatusComponent,
    ActivePrincipleTagComponent,
    BtnReportTemplateComponent,
    SelectComponent,
    Select2Component,
    GaugeChartComponent,
    GenericModalComponent
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
  ]
})
export class SharedModule { }
