import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-flowchart-delete-modal',
  templateUrl: './flowchart-delete-modal.component.html',
  styleUrls: ['./flowchart-delete-modal.component.css']
})
export class FlowchartDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FlowchartDeleteModalComponent>,
  ) { }


  ngOnInit(): void {

  }

  clickDelete() {
    this.matDialogRef.close({ deleteFlowchart: true });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteFlowchart: false });
  }
}
