export const environment = {
  production: false,
  staging:true,
  hashEmailSmsAuthorization: "2d62ddbb4cef30c726b8e627a752041b",
  googleClientId: "818565667452-l7bkgpe5luvjce3l2d37od33naes8fr1.apps.googleusercontent.com",
  urlApiBase: "https://clusterstaging.tolife.app/",
  urlApiBaseGPA: "https://gpa.clusterstaging.tolife.app/",
  urlApiAuth: "https://api.clusterstaging.tolife.app/tolifecloud-api-auth/",
  urlApiSrvEmail: "https://api.clusterstaging.tolife.app/tolifecloud-api-srvemail/",
  urlApiAdminUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-admin-user/",
  urlApiUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-user/",
  urlOrchestratorIntegration: "https://api.clusterstaging.tolife.app/integration/",
  urlApiSrvLog: "https://api.clusterstaging.tolife.app/tolifecloud-api-srvlog/",
  urlApiDevice: "https://api.clusterstaging.tolife.app/tolifecloud-api-device/",
  urlApiRiskClassification: "https://api.clusterstaging.tolife.app/tolifecloud-api-risk-classification/",
  urlReport: "https://api.clusterstaging.tolife.app/tolifecloud-api-report/",
  urlApiBilling:"https://api.clusterstaging.tolife.app/tolifecloud-api-billing/",
  urlApiBusinessIntelligence: "https://api.clusterstaging.tolife.app/tolifecloud-api-business-intelligence/",
  urlApiPharmacy: "https://api.clusterstaging.tolife.app/tolifecloud-api-pharmacy/",
  urlApiNotification: "https://api.clusterstaging.tolife.app/tolifecloud-api-notification/",
  urlApiOrchestratorUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-user/",
  urlApiIntegration: "https://api.clusterstaging.tolife.app/tolifecloud-api-integration/",
  urlApiEmerges: "https://api.clusterstaging.tolife.ap/emerges-key/",
  urlApiIntegrationRelationConfig: "https://api.clusterstaging.tolife.app/tolifecloud-api-integration-relation-config/",
  urlApiEmergesKey: "https://api.clusterstaging.tolife.app/tolifecloud-api-emerges-key/",
  urlApiSelfTriage: "https://api.clusterstaging.tolife.app/tolifecloud-api-self-triage/",  
  urlApiMedicalRecord: "https://api.clusterstaging.tolife.app/tolifecloud-api-medical-record/",
  urlApiIntegrationSiga: "https://api.clusterstaging.tolife.app/tolifecloud-api-integration-siga/",
  urlApiBillingByHealthUnit: "https://api.clusterstaging.tolife.app/tolifecloud-api-billing-by-health-unit/",
  urlApiOrchestratorBillingByHealthUnit: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-billing-by-health-unit/",
  urlApicareLinePriority: "https://api.clusterstaging.tolife.app/tolifecloud-api-care-line-priority/",
  urlApiCsat: "https://api.clusterstaging.tolife.app/tolifecloud-api-csat/",
  urlApiSRVMessageQueue:"https://api.clusterstaging.tolife.app/tolifecloud-api-srv-message-queue/",
  urlApiOrchestratorBusinessIntelligence: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-business-intelligence/",
  urlApiCache: "https://api.clusterstaging.tolife.app/tolifecloud-api-cache/",
  urlApiDataMesh: "https://api.clusterstaging.tolife.app/tolifecloud-api-data-mesh/",
  urlApiOrchestratorClassification: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-classification/",
};