<div class="row">
    <div class="priority-container col-12" *ngFor="let color of listPriorityColor">
        <span [ngStyle]="{'background-color': color.coloerHex}"
            class="nivel-urgencia">{{color.priorityColorName}}</span>
        <div class="card-list row">
            <div class="col-12 col-sm-6 col-md-4"
                *ngFor="let item of this.discriminatorsByColor[color.idPriorityColor]; let j = index;">
                <div class="card-item">
                    <div class="infos">
                        <span [matTooltip]="item.discriminatorName+' - ('+item.order+')'" [matTooltipPosition]="'above'"
                            class="primary">{{item.discriminatorName}} - ({{item.order}})</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openModal(color.idPriorityColor, j, item)">edit
                            </mat-icon>
                        </a>
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="openDeleteModal(color.idPriorityColor,j)">
                                delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <button class="button-create btn-secundary" mat-stroked-button type="button"
                    (click)="openModal(color.idPriorityColor,null, null)" [disabled]="!flowchartForm.valid">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    <span *ngIf="!isLoading">Adicionar novo discriminador</span>
                </button>
            </div>
        </div>
    </div>
</div>