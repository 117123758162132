<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Relatório</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-12 col-sm-6">
                <a mat-button class="btn-primary add-user" routerLink="/bi/report/register"><mat-icon
                        aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Relatório</a>
            </div>

            <div class="col-12 col-sm-6 col-md-6 button-report">
                <a mat-button class="btn-primary add-user" (click)="openPowerBiModal()"> Relatório não cadastrados</a>
            </div>
        </div>

        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="isActive" (selectionChange)="search($event)">
                            <mat-option value="">Todos</mat-option>
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade de Saúde</mat-label>
                        <mat-select [(ngModel)]="idHealthUnit" (selectionChange)="search($event)">
                            <input class="inputSearchSelect" type="text" (keyup)="onKeySearchHealthUnit($event.target.value)"
                                (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                                autocomplete="off">
                            <mat-option [value]="default">SELECIONE</mat-option>
                            <mat-option *ngFor="let item of listHealthUnit" value="{{item.idHealthUnit}}">
                                {{item.healthUnitName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordenação</mat-label>
                        <mat-select [(ngModel)]="ordenation" (selectionChange)="search($event)">
                            <mat-option>Todas</mat-option>
                            <mat-option *ngFor="let item of listOrdenation" value="{{item}}">
                                {{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2 toggle-style">
                    <mat-slide-toggle (change)="search($event)"
                        [(ngModel)]="isNotUsed"></mat-slide-toggle><mat-label>Relatórios não utilizados</mat-label>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listReport != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listReport">
                    <div class="card-item">
                        <div class="infos2">
                            <span class="primary">{{item.reportName}}</span>
                            <span class="secundary">
                                <span
                                    [ngClass]="{'secundary': item.powerBiReportName, 'color-red': !item.powerBiReportName}">{{item.powerBiReportName
                                    ? 'Relatório: '+item.powerBiReportName+'.pbix' : 'Relátorio pbix não
                                    encontrado'}}</span>
                            </span>
                            <span class="secundary">Ordenação: {{item.ordenation}}</span>
                            <span class="secundary">Unidades: {{item.countHealthUnit}}</span>
                            <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                        </div>
                        <div class="actions actions2">
                            <a *ngIf="item.powerBiReportName"><mat-icon aria-hidden="false" aria-label="Schedule"
                                (click)="scheduleModal(item.idReport, item.powerBiReportName)">restart_alt</mat-icon> </a>

                            <a><mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/bi/report/register', {idReport: item.idReport}]">edit</mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Excluir"
                                    (click)="openModal(item.idReport)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listReport != undefined && listReport.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>