import { FlowchartModel } from "../../models/risk-classification/flowchart.model";
import { AgeStruct } from "./age.struct";
import { DiscriminatorStruct } from "./discriminator.struct";

export class CompleteFlowchartStruct extends FlowchartModel {

    public flowchartGroup: number[];

    public protocolName: string;

    public listDiscriminators: DiscriminatorStruct[];

    public minAgeStruct:AgeStruct;

    public maxAgeStruct:AgeStruct;
}