import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorTypeModel } from 'src/app/shared/services/models/risk-classification/discriminator-type.model';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { SimpleFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/simple-flowchart.request';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { SimpleFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/simple-flowchart.struct';
import { DiscriminatorAddModalComponent } from './discriminator-add-modal/discriminator-add-modal.component';
import { DiscriminatorDeleteModalComponent } from './discriminator-delete-modal/discriminator-delete-modal.component';

@Component({
  selector: 'app-discriminator-list',
  templateUrl: './discriminator-list.component.html',
  styleUrls: ['./discriminator-list.component.css']
})
export class DiscriminatorListComponent implements OnInit, OnChanges {

  constructor(private dialog: MatDialog,
    private discriminatorService: DiscriminatorService,
    private flowchartService: FlowchartService,
    private alertService: AlertService,) { }

  @Input() listPriorityColor: PriorityColorModel[];
  @Input() listDiscriminator: DiscriminatorStruct[];
  @Input() listDiscriminatorType: DiscriminatorTypeModel[];
  @Input() listCounseling: CounselingModel[];
  @Input() flowchartForm: FormGroup;
  @Input() idProtocol: number;
  @Input() isLoading: boolean;
  @Output() selectedValue = new EventEmitter<DiscriminatorStruct[]>();
  @Output() saveFlowchart = new EventEmitter<any>();

  public discriminatorsByColor: any[][];

  ngOnInit(): void {
    this.discriminatorsByColor = [];
    this.listPriorityColor.forEach((c) => {
      if (this.listDiscriminator) {
        this.discriminatorsByColor[c.idPriorityColor] = this.listDiscriminator.filter(x => x.idPriorityColor == c.idPriorityColor);
      } else {
        this.discriminatorsByColor[c.idPriorityColor] = [];
      }
    });
  }

  ngOnChanges(): void {

  }

  openDeleteModal(idPriorityColor: number, j: number) {
    const dialogRef = this.dialog.open(DiscriminatorDeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteDiscriminator) {
        this.deleteDiscriminator(idPriorityColor, j);
      }
    });
  }

  deleteDiscriminator(idPriorityColor: number, j: number) {
    let indexTotalList: number = 0;
    let idDiscriminator = this.discriminatorsByColor[idPriorityColor][j].idDiscriminator;

    this.isLoading = true;
    this.discriminatorService.DeleteDiscriminator(idDiscriminator).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Discriminador removido com sucesso', AlertType.success);
        this.discriminatorsByColor.forEach((c, index) => {
          if (index < idPriorityColor) {
            indexTotalList += c.length;
          }
          else {
            return;
          }
        });

        indexTotalList += j;

        this.discriminatorsByColor[idPriorityColor].splice(j, 1);
        this.listDiscriminator.splice(indexTotalList, 1);
        this.onChangeDiscriminators();
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idPriorityColor: number, j: number, item: DiscriminatorStruct) {
    if (!this.flowchartForm.get('isFlowchartUpdate').value) {

      let request: SimpleFlowchartRequest = new SimpleFlowchartRequest();
      let flowchart = new SimpleFlowchartStruct();

      let flowchartForm = this.flowchartForm['controls'];

      flowchart.idProtocol = this.idProtocol;
      flowchart.flowchartName = flowchartForm.name.value;
      flowchart.description = flowchartForm.description.value;
      flowchart.isPunctuationSystem = false;
      flowchart.order = flowchartForm.order.value;
      flowchart.flowchartGroup = flowchartForm.idFlowchartGroup.value;
      flowchart.idFlowchartType = flowchartForm.idFlowchartType.value;
      flowchart.minAgeStruct = this.formatDateToSave(flowchartForm.minAge.value);
      flowchart.maxAgeStruct = this.formatDateToSave(flowchartForm.maxAge.value);

      request.flowchart = flowchart;

      this.isLoading = true;

      this.flowchartService.PostSimpleFlowchart(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          else {
            this.alertService.show('Sucesso', "Fluxograma criado com sucesso", AlertType.success);
            this.isLoading = false;

            this.flowchartForm.get('idFlowchart').setValue(response.idFlowchart);
            this.flowchartForm.get('isFlowchartUpdate').setValue(true);
            this.flowchartForm.markAsPristine();

            const dialogRef = this.dialog.open(DiscriminatorAddModalComponent, {
              data: {
                discriminator: item,
                idPriorityColor: idPriorityColor,
                idFlowchart: this.flowchartForm.get('idFlowchart').value,
                listDiscriminatorType: this.listDiscriminatorType,
                listDiscriminator: this.listDiscriminator,
                listCounseling: this.listCounseling
              },
            });

            dialogRef.afterClosed().subscribe((result: DiscriminatorStruct) => {
              if (result) {
                if (item) {
                  result.idDiscriminator = this.discriminatorsByColor[idPriorityColor][j].idDiscriminator;
                  result.idPriorityColor = idPriorityColor;
                  this.discriminatorsByColor[idPriorityColor][j] = result;
                }
                else {
                  result.idPriorityColor = idPriorityColor;
                  this.discriminatorsByColor[idPriorityColor].push(result);
                }
                this.listDiscriminator = [];
                this.discriminatorsByColor.forEach((c: DiscriminatorStruct[]) => {
                  if (c && Array.isArray(c) && c.length > 0) {
                    this.listDiscriminator = this.listDiscriminator.concat(c)
                  }
                });
                this.onChangeDiscriminators();
              }
            });
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });

    }

    else {
      const dialogRef = this.dialog.open(DiscriminatorAddModalComponent, {
        data: {
          discriminator: item,
          idPriorityColor: idPriorityColor,
          idFlowchart: this.flowchartForm.get('idFlowchart').value,
          listDiscriminatorType: this.listDiscriminatorType,
          listDiscriminator: this.listDiscriminator,
          listCounseling: this.listCounseling
        },
      });

      dialogRef.afterClosed().subscribe((result: DiscriminatorStruct) => {
        if (result) {
          if (item) {
            result.idDiscriminator = this.discriminatorsByColor[idPriorityColor][j].idDiscriminator;
            result.idPriorityColor = idPriorityColor;
            this.discriminatorsByColor[idPriorityColor][j] = result;
          }
          else {
            result.idPriorityColor = idPriorityColor;
            this.discriminatorsByColor[idPriorityColor].push(result);
          }
          this.listDiscriminator = [];
          this.discriminatorsByColor.forEach((c: DiscriminatorStruct[]) => {
            if (c && Array.isArray(c) && c.length > 0) {
              this.listDiscriminator = this.listDiscriminator.concat(c)
            }
          });
          this.onChangeDiscriminators();
        }
      });
    }
  }

  //Toda vez que eu atualizo o discriminador, também é atualizada a lista completa de discriminadores
  onChangeDiscriminators() {
    this.selectedValue.emit([...this.listDiscriminator]);
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }
}
