import { CommonModule } from '@angular/common';
import { ConsultingRoutingModule } from './consulting-routing.module';
import { ConsultingComponent } from './pages/consulting/consulting.component';
import { HealthUnitListComponent } from './pages/health-unit-list/health-unit-list.component';
import { HealthUnitRegisterComponent } from './pages/health-unit-register/health-unit-register.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { MatNativeDateModule, MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';

import { SharedModule } from '../shared/shared.module';
import { HealthUnitLogoModalComponent } from './pages/health-unit-register/health-unit-logo-modal/health-unit-logo-modal.component';
import { BillingSigtapRegisterComponent } from './pages/billing-sigtap-register/billing-sigtap-register.component';
import { BillingSigtapListComponent } from './pages/billing-sigtap-list/billing-sigtap-list.component';
import { BillingSigtapDeleteModalComponent } from './pages/billing-sigtap-list/billing-sigtap-delete-modal/billing-sigtap-delete-modal.component';
import { BillingSigtapTriggerModalComponent } from './pages/billing-sigtap-register/billing-sigtap-trigger-modal/billing-sigtap-trigger-modal.component';
import { ProtocolDeleteModalComponent } from './pages/protocol-list/protocol-delete-modal/protocol-delete-modal.component';
import { ProtocolListComponent } from './pages/protocol-list/protocol-list.component';
import { ProtocolRegisterComponent } from './pages/protocol-register/protocol-register.component';
import { FlowchartAccordionComponent } from './pages/protocol-register/flowchart-accordion/flowchart-accordion.component';
import { TemplateSubgroupRegisterComponent } from './pages/template-subgroup-register/template-subgroup-register.component';
import { DiscriminatorListComponent } from './pages/protocol-register/discriminator-list/discriminator-list.component';
import { DiscriminatorAddModalComponent } from './pages/protocol-register/discriminator-list/discriminator-add-modal/discriminator-add-modal.component';
import { TemplateComponent } from './pages/template/template.component';
import { TemplateTypeComponent } from './pages/template-type/template-type.component';
import { TemplateGroupComponent } from './pages/template-group/template-group.component';
import { TemplateSubgroupComponent } from './pages/template-subgroup/template-subgroup.component';
import { TemplateMedicationComponent } from './pages/template-medication/template-medication.component';
import { TemplateRegisterComponent } from './pages/template-register/template-register.component';
import { TemplateDeleteModalComponent } from './pages/template/template-delete-modal/template-delete-modal.component';
import { TemplateSubgroupDeleteModalComponent } from './pages/template-subgroup/template-subgroup-delete-modal/template-subgroup-delete-modal.component';
import { TemplateTypeRegisterComponent } from './pages/template-type-register/template-type-register.component';
import { TemplateGroupRegisterComponent } from './pages/template-group-register/template-group-register.component';
import { TemplateTypeDeleteModalComponent } from './pages/template-type/template-type-delete-modal/template-type-delete-modal.component';
import { TemplateGroupDeleteModalComponent } from './pages/template-group/template-group-delete-modal/template-group-delete-modal.component';
import { TemplateMedicationRegisterComponent } from './pages/template-medication-register/template-medication-register.component';
import { TemplateMedicineDeleteModalComponent } from './pages/template-medication/template-medicine-delete-modal/template-medicine-delete-modal.component';
import { CounselingListComponent } from './pages/counseling-list/counseling-list.component';
import { CounselingDeleteModalComponent } from './pages/counseling-list/counseling-delete-modal/counseling-delete-modal.component';
import { CounselingRegisterComponent } from './pages/counseling-register/counseling-register.component';
import { SelfTriageFlowchartListComponent } from './pages/self-triage-flowchart-list/self-triage-flowchart-list.component';
import { SelfTriageFlowchartDeleteModalComponent } from './pages/self-triage-flowchart-list/self-triage-flowchart-delete-modal/self-triage-flowchart-delete-modal.component';
import { SelfTriageFlowchartRegisterComponent } from './pages/self-triage-flowchart-register/self-triage-flowchart-register.component';
import { SelfTriageProtocolListComponent } from './pages/self-triage-protocol-list/self-triage-protocol-list.component';
import { SelfTriageProtocolDeleteModalComponent } from './pages/self-triage-protocol-list/self-triage-protocol-delete-modal/self-triage-protocol-delete-modal.component';
import { SelfTriageProtocolRegisterComponent } from './pages/self-triage-protocol-register/self-triage-protocol-register.component';
import { SelfTriageCounselingListComponent } from './pages/self-triage-counseling-list/self-triage-counseling-list.component';
import { SelfTriageCounselingDeleteModalComponent } from './pages/self-triage-counseling-list/self-triage-counseling-delete-modal/self-triage-counseling-delete-modal.component';
import { SelfTriageCounselingRegisterComponent } from './pages/self-triage-counseling-register/self-triage-counseling-register.component';
import { SelfTriageDiscriminatorDeleteModalComponent } from './pages/self-triage-discriminator-list/self-triage-discriminator-delete-modal/self-triage-discriminator-delete-modal.component';
import { SelfTriageDiscriminatorListComponent } from './pages/self-triage-discriminator-list/self-triage-discriminator-list.component';
import { SelfTriageDiscriminatorRegisterComponent } from './pages/self-triage-discriminator-register/self-triage-discriminator-register.component';
import { QuestionComponent } from './pages/self-triage-discriminator-register/question/question.component';
import { AnswerModelComponent } from './pages/self-triage-discriminator-register/question/answer-modal/anwser-modal.component';
import { AnswerDeleteModalComponent } from './pages/self-triage-discriminator-register/question/answer-delete-modal/answer-delete-modal.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { NotificationAccordionComponent } from './pages/notification/notification-accordion/notification-accordion.component';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ChangeMasterUserModalComponent } from './pages/health-unit-register/change-master-user-modal/change-master-user-modal.component';
import { FlowchartDeleteModalComponent } from './pages/protocol-register/flowchart-accordion/flowchart-delete-modal/flowchart-delete-modal.component';
import { DiscriminatorDeleteModalComponent } from './pages/protocol-register/discriminator-list/discriminator-delete-modal/discriminator-delete-modal.component';
import { CoordinationDeleteModalComponent } from './pages/coordination-list/coordination-delete-modal/coordination-delete-modal.component';
import { CoordinationListComponent } from './pages/coordination-list/coordination-list.component';
import { CoordinationRegisterComponent } from './pages/coordination-register/coordination-register.component';
import { SupervisionRegisterComponent } from './pages/supervision-register/supervision-register.component';
import { SupervisionListComponent } from './pages/supervision-list/supervision-list.component';
import { SupervisionDeleteModalComponent } from './pages/supervision-list/supervision-delete-modal/supervision-delete-modal.component';
import { CareLinePriorityListComponent } from './pages/care-line-priority-list/care-line-priority-list.component';
import { CareLinePriorityDeleteModalComponent } from './pages/care-line-priority-list/care-line-priority-delete-modal/care-line-priority-delete-modal.component';
import { CareLinePriorityRegisterComponent } from './pages/care-line-priority-register/care-line-priority-register.component';
import { TemplateBiConfirmModalComponent } from './pages/health-unit-register/template-bi-confirm-modal/template-bi-confirm-modal.component';
import { OriginAndArrivalTypeComponent } from './pages/origin-and-arrival-type/origin-and-arrival-type.component';
import { SuspicionListComponent } from './pages/suspicion-list/suspicion-list.component';
import { SuspicionRegisterComponent } from './pages/suspicion-register/suspicion-register.component';
import { OriginGroupListComponent } from './pages/origin-and-arrival-type/origin-group/origin-group-list.component';
import { OriginGroupRegisterComponent } from './pages/origin-and-arrival-type/origin-group/origin-group-register/origin-group-register.component';
import { OriginGroupDeleteModalComponent } from './pages/origin-and-arrival-type/origin-group/origin-group-delete-modal/origin-group-delete-modal.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    TemplateSubgroupDeleteModalComponent,
    ConsultingComponent,
    HealthUnitListComponent,
    HealthUnitRegisterComponent,
    TemplateSubgroupRegisterComponent,
    HealthUnitLogoModalComponent,
    BillingSigtapRegisterComponent,
    BillingSigtapListComponent,
    BillingSigtapDeleteModalComponent,
    BillingSigtapTriggerModalComponent,
    ProtocolDeleteModalComponent,
    ProtocolListComponent,
    ProtocolRegisterComponent,
    FlowchartAccordionComponent,
    DiscriminatorListComponent,
    DiscriminatorAddModalComponent,
    TemplateComponent,
    TemplateTypeComponent,
    TemplateGroupComponent,
    TemplateSubgroupComponent,
    TemplateMedicationComponent,
    TemplateRegisterComponent,
    TemplateDeleteModalComponent,
    TemplateTypeRegisterComponent,
    TemplateGroupRegisterComponent,
    TemplateTypeDeleteModalComponent,
    TemplateGroupDeleteModalComponent,
    TemplateMedicationRegisterComponent,
    TemplateMedicineDeleteModalComponent,
    CounselingListComponent,
    CounselingDeleteModalComponent,
    CounselingRegisterComponent,
    SelfTriageFlowchartListComponent,
    SelfTriageFlowchartRegisterComponent,
    SelfTriageFlowchartDeleteModalComponent,
    SelfTriageProtocolListComponent,
    SelfTriageProtocolRegisterComponent,
    SelfTriageProtocolDeleteModalComponent,
    SelfTriageCounselingListComponent,
    SelfTriageCounselingDeleteModalComponent,
    SelfTriageCounselingRegisterComponent,
    SelfTriageDiscriminatorDeleteModalComponent,
    SelfTriageDiscriminatorListComponent,
    SelfTriageDiscriminatorRegisterComponent,
    QuestionComponent,
    AnswerModelComponent,
    AnswerDeleteModalComponent,
    NotificationComponent,
    NotificationAccordionComponent,
    ChangeMasterUserModalComponent,
    FlowchartDeleteModalComponent,
    DiscriminatorDeleteModalComponent,
    CoordinationDeleteModalComponent,
    CoordinationListComponent,
    CoordinationRegisterComponent,
    SupervisionRegisterComponent,
    SupervisionListComponent,
    SupervisionDeleteModalComponent,
    CareLinePriorityListComponent,
    CareLinePriorityRegisterComponent,
    CareLinePriorityDeleteModalComponent,
    TemplateBiConfirmModalComponent,
    OriginAndArrivalTypeComponent,
    SuspicionListComponent,
    SuspicionRegisterComponent,
    OriginGroupListComponent,
    OriginGroupRegisterComponent,
    OriginGroupDeleteModalComponent
  ],
  imports: [
    CommonModule,
    ConsultingRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatOptionModule,
    NgxMaskModule.forRoot(),
    NgxMatFileInputModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class ConsultingModule { }
