import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorTypeModel } from 'src/app/shared/services/models/risk-classification/discriminator-type.model';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { CompleteFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/complete-flowchart.struct';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { DiscriminatorRequest } from 'src/app/shared/services/requests/risk-classification/discriminator.request';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { protocol } from 'socket.io-client';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { SimpleFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/simple-flowchart.request';
import { SimpleFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/simple-flowchart.struct';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowchartTypeEnum } from 'src/app/shared/enum/flowchart-type.enum';
import { FlowchartType } from 'src/app/shared/services/models/risk-classification/flowchart-type.model';
import { VerifyAgeStruct } from 'src/app/shared/custom-validators/ageStruct.validator';
import { MatDialog } from '@angular/material/dialog';
import { FlowchartDeleteModalComponent } from './flowchart-delete-modal/flowchart-delete-modal.component';

@Component({
  selector: 'app-flowchart-accordion',
  templateUrl: './flowchart-accordion.component.html',
  styleUrls: ['./flowchart-accordion.component.css']
})
export class FlowchartAccordionComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private maskService: MaskService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private flowchartService: FlowchartService) { }

  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() listFlowchart: CompleteFlowchartStruct[];
  @Input() listFlowchartGroup: FlowchartGroupModel[];
  @Input() listPriorityColor: PriorityColorModel[] = [];
  @Input() listDiscriminatorType: DiscriminatorTypeModel[];
  @Input() listCounseling: CounselingModel[];
  @Input() listFlowchartType: FlowchartType[];
  @Input() isUpdate: boolean;
  @Output() createSimpleProtocol: EventEmitter<ProtocolModel> = new EventEmitter<ProtocolModel>();

  public masks: Masks;
  public isLoading: boolean;
  public FlowchartTypeEnum: FlowchartTypeEnum;


  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
  }

  createInput() {
    return this.formBuilder.group({
      idFlowchart: [''],
      name: ['', [Validators.required]],
      minAge: ['', { validators: [VerifyAgeStruct()] }],
      maxAge: ['', [VerifyAgeStruct()]],
      order: ['', [Validators.required]],
      description: [''],
      idFlowchartGroup: [null, [Validators.required]],
      idFlowchartType: [null, [Validators.required]],
      isFlowchartUpdate: [false],
    });
  }

  addNext(index: number) {
    if (!this.isUpdate) {
      let protocol: ProtocolModel = new ProtocolModel();
      protocol.protocolName = this.formGroup.get('protocolName').value;
      protocol.description = this.formGroup.get('description').value;
      protocol.isTemplate = this.formGroup.get('isTemplate').value;
      protocol.isActive = this.formGroup.get('isActive').value;
      protocol.outOfOrder = this.formGroup.get('isOutOfOrder').value;
      protocol.idPriorityColorGroup = this.formGroup.get('idPriorityColorGroup').value;
      protocol.isTelephone = this.formGroup.get('isTelephone').value;
      protocol.isDeleted = false;
      this.createSimpleProtocol.emit(protocol);
    }

    let newFlowchart: CompleteFlowchartStruct = new CompleteFlowchartStruct;
    newFlowchart.listDiscriminators = [];
    this.listFlowchart.splice(index + 1, 0, newFlowchart);
    (this.formGroup.controls[this.formArrayName] as FormArray).insert(index + 1, this.createInput())
  }

  openDeleteModal(index: number) {
    const dialogRef = this.dialog.open(FlowchartDeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteFlowchart) {
        this.removeExpression(index);
      }
    });
  }


  removeExpression(index: number) {
    let flowchartForm = this.formGroup.get(this.formArrayName)['controls'][index];

    if (flowchartForm.get('idFlowchart').value) {
      this.deleteFlowchart(flowchartForm.get('idFlowchart').value);
    }

    this.listFlowchart.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as FormArray).removeAt(index);
  }

  changeName(value: string, i: number) {
    this.listFlowchart[i].flowchartName = value;
  }

  changeOrder(value: number, i: number) {
    this.listFlowchart[i].order = value;
  }

  validateAge(value: string, index: number, type: string = null): boolean {

    if (!value) {
      return true;
    }

    const pattern = /^(([0-9]{3}|[0-9]{2})a(0[0-9]|1[01])m((0|1|2)[0-9]|30)d)$/;

    let flowchartForm = this.formGroup.get(this.formArrayName)['controls'][index];

    if (!pattern.test(value)) {

      if (type === 'min') {
        flowchartForm.get('minAge').setErrors({ invalidAge: true });
      }

      if (type === 'max') {
        flowchartForm.get('maxAge').setErrors({ invalidAge: true });
      }

      return false;
    }

    if (value) {
      let result = this.formatDateToSave(value);
      if (result.months > 12) {
        this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 12", AlertType.error);
        return false;
      } else if (result.days > 30) {
        this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 30", AlertType.error);
        return false;
      }

      return true;
    }
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }

  UpdateArrayDiscriminator(i: number, value: DiscriminatorStruct[]) {
    this.listFlowchart[i].listDiscriminators = value;
  }

  saveFlowchart(index: number) {
    this.isLoading = true;
    let flowchartForm = this.formGroup.get(this.formArrayName)['controls'][index];

    let isValidAge: boolean = false;

    let minAge = flowchartForm.get('minAge').value;
    let maxAge = flowchartForm.get('maxAge').value;

    isValidAge = this.validateAge(minAge, index, 'min');

    isValidAge = this.validateAge(maxAge, index, 'max');

    if (!isValidAge || flowchartForm.invalid) {
      this.isLoading = false;
      return;
    }

    let flowchart: SimpleFlowchartStruct = new SimpleFlowchartStruct();

    flowchart.idProtocol = this.formGroup.get('idProtocol').value ? this.formGroup.get('idProtocol').value : null;
    flowchart.flowchartName = flowchartForm.value.name;
    flowchart.description = flowchartForm.value.description;
    flowchart.isPunctuationSystem = false;
    flowchart.order = flowchartForm.value.order;
    flowchart.flowchartGroup = flowchartForm.value.idFlowchartGroup;
    flowchart.idFlowchartType = flowchartForm.value.idFlowchartType;
    flowchart.minAgeStruct = this.formatDateToSave(flowchartForm.value.minAge);
    flowchart.maxAgeStruct = this.formatDateToSave(flowchartForm.value.maxAge);

    if (flowchartForm.value.idFlowchart) {
      flowchart.idFlowchart = flowchartForm.value.idFlowchart;
      this.updateFlowchart(flowchart, index);
    }

    else {
      this.createFlowchart(flowchart, index);
    }
  }

  updateFlowchart(flowchart: SimpleFlowchartStruct, index: number) {
    let request: SimpleFlowchartRequest = new SimpleFlowchartRequest();
    request.flowchart = flowchart;

    this.flowchartService.UpdateSimpleFlowchart(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
      }

      else {
        this.alertService.show('Sucesso', "Fluxograma atualizado com sucesso", AlertType.success);
        this.isLoading = false;
        this.formGroup.get(this.formArrayName)['controls'][index].markAsPristine();
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  createFlowchart(flowchart: SimpleFlowchartStruct, index: number) {
    let request: SimpleFlowchartRequest = new SimpleFlowchartRequest();
    request.flowchart = flowchart;

    this.flowchartService.PostSimpleFlowchart(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else {
          this.alertService.show('Sucesso', "Fluxograma criado com sucesso", AlertType.success);
          this.isLoading = false;

          let flowchartForm = this.formGroup.get(this.formArrayName)['controls'][index];

          flowchartForm.get('idFlowchart').setValue(response.idFlowchart);
          flowchartForm.get('isFlowchartUpdate').setValue(true);
          flowchartForm.markAsPristine();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  deleteFlowchart(idFlowchart: number) {
    this.flowchartService.DeleteFlowchart(idFlowchart).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else {
          this.alertService.show('Sucesso', "Fluxograma deletado com sucesso", AlertType.success);
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
