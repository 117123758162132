import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';
import { Report } from 'src/app/shared/services/models/business-intelligence/report.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ReportDeleteModalComponent } from './report-delete-modal/report-delete-modal.component';
import { PowerBiService } from 'src/app/shared/services/API/business-intelligence/power-bi.service';
import { ReportStruct } from 'src/app/shared/services/structs/business-intelligence/report.struct';
import { ReportPowerBiModalComponent } from './report-power-bi-modal/report-power-bi-modal.component';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { ReportScheduleModalComponent } from './report-schedule-modal/report-schedule-modal.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  constructor(
    private reportService: ReportService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public listHealthUnitService: ListHealthUnitService,
    public powerBiService: PowerBiService
  ) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.business_intelligence;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_report;    
    public listReport: Report[];
    public isLoading: boolean;
    public searchText: string;
    public isActive: boolean;
    public isNotUsed: boolean;
    public ordenation: number;
    public idHealthUnit: number;
    public listHealthUnit: ListHealthUnitStruct[];
    public listOrdenation: number[];
    public default: boolean = true;

  ngOnInit(): void {
    this.isLoading = true;

    this.populateHealthUnitSelect(null);
    this.search(null);
  }

  search(event: any){
    if(event != null && event.value == this.default)
      return;
    
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.reportService.listReport(this.searchText, this.isActive, this.isNotUsed, this.idHealthUnit, this.ordenation).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listReport = response.listReport;
        this.listOrdenation = response.listOrdenation;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitSelect(searchText: string) {

    if (searchText == "")
      searchText = null;

    this.listHealthUnitService.listHealthUnit(searchText, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listHealthUnit = response.list;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search(null);
      }
    }, 1000);
  }

  refreshReport(idReport: number) {
    this.powerBiService.Refresh(idReport).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Refresh iniciado", AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idReport) {
    const dialogRef = this.dialog.open(ReportDeleteModalComponent, {
      data: {
        idReport: idReport
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteReport){
        this.search(null);
      }
    });
  }


    scheduleModal(idReport, powerBiReportName){
      const dialogRef = this.dialog.open(ReportScheduleModalComponent, {
        data: {
          idReport: idReport,
          powerBiReportName : powerBiReportName
        },
      });
    }





    openPowerBiModal(){
      this.dialog.open(ReportPowerBiModalComponent);
    }

  private timeoutKeySearchHealthUnit: any = null;

  onKeySearchHealthUnit(event: any) {
    clearTimeout(this.timeoutKeySearchHealthUnit);
    var $this = this;
    this.timeoutKeySearchHealthUnit = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect(event);
      }
    }, 1000);
  }

}


