<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consultation/protocol">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar Protocolo</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Protocolo</h1>
                
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome Protocolo</mat-label>
                        <input matInput type="text" formControlName="protocolName">
                        <mat-error *ngIf="model.get('protocolName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo de cor</mat-label>
                        <mat-select (selectionChange)="updateListColor($event.value)"
                            formControlName="idPriorityColorGroup">
                            <mat-option *ngFor="let item of listPriorityColorGroup" [value]="item.idPriorityColorGroup">
                                {{item.priorityColorGroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="description">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Ativo</mat-label>
                        <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>É Padrão para todas as unidades</mat-label>
                        <mat-slide-toggle formControlName="isTemplate"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>É fora de ordem</mat-label>
                        <mat-slide-toggle formControlName="isOutOfOrder"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Pode ser usado por Telefone?</mat-label>
                        <mat-slide-toggle formControlName="isTelephone"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div *ngIf="isAfterLoading && !isFirstLoading" class="row">
                <app-flowchart-accordion [listDiscriminatorType]="listDiscriminatorType"
                    [listPriorityColor]="listPriorityColor" [listFlowchartGroup]="listFlowchartGroup"
                    [formGroup]="this.model" [formArrayName]="'flowchart'" [listFlowchart]="listFlowchart"
                    [listCounseling]="listCounseling" [listFlowchartType]="listFlowchartType"
                    [isUpdate]="isUpdate" (createSimpleProtocol)="createSimpleProtocol($event)">
                </app-flowchart-accordion>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block"
                        [disabled]="!model.get('protocolName').touched && !model.get('description').touched && !model.get('isActive').touched && !model.get('isTelephone').touched && !model.get('idPriorityColorGroup').touched">
                        <span *ngIf="isLoading == false">Salvar tudo</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/protocol"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>