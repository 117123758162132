import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutBatchConfigurationRequest } from '../../requests/srv-log/post-put-batch-configuration.request';
import { GetBatchConfigurationResponse } from '../../responses/srvlog/get-batch-configuration.response';
import { GetLogResponse } from '../../responses/srvlog/get-log.response';
import { GetLogsResponse } from '../../responses/srvlog/get-logs.response';
import { GetSensitiveLogDataResponse } from '../../responses/srvlog/get-sensitive-log-data.response';
import { ListBatchConfigurationResponse } from '../../responses/srvlog/list-batch-configuration.response';

@Injectable({
  providedIn: 'root'
})
export class BatchConfigurationVisualizerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public listBatch(): Observable<ListBatchConfigurationResponse> {

    let uri = 'BatchConfigurationVisualizer'

    return this.httpClient.get<ListBatchConfigurationResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getBatch(idBatchConfiguration: number): Observable<GetBatchConfigurationResponse> {

    let uri = `BatchConfigurationVisualizer/idBatchConfiguration/${idBatchConfiguration}`

    return this.httpClient.get<GetBatchConfigurationResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateBatch(body: PostPutBatchConfigurationRequest, idBatchConfiguration: number): Observable<ReturnStruct> {

    let uri = `BatchConfigurationVisualizer/idBatchConfiguration/${idBatchConfiguration}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSrvLog + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public runBatch(idBatchConfiguration: number): Observable<ReturnStruct> {

    let uri = `BatchConfigurationVisualizer/run/idBatchConfiguration/${idBatchConfiguration}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSrvLog + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteBatch(idBatchConfiguration: number): Observable<ReturnStruct> {

    let uri = `BatchConfigurationVisualizer/idBatchConfiguration/${idBatchConfiguration}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createBatch(body: PostPutBatchConfigurationRequest): Observable<ReturnStruct> {

    let uri = `BatchConfigurationVisualizer`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSrvLog + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
